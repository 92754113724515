// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * @author username
 * 
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { Images } from '../../../constants/assets';

import styles from './Logo.module.scss';

interface LogoProps {
    text: string;
};

const Logo = ({ text }: LogoProps) => {

    return (
        <Link to="/" className={[styles['logo']].join(' ')}>
            <img src={Images.logo} alt="logo" width={60} />
            <span>{text}</span>
        </Link>
    );
}

export default Logo;