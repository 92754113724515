import { AuthenticationResult } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import { useCallback, useState } from "react";
import { AuthN } from "../../library/security";

const useService = <T>() => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const consumeService = useCallback(async (
        scopes: string[],
        callback: (respose: AuthenticationResult) => Promise<any> | Promise<void> | Promise<T>
    ) => {
        setIsLoading(true);
        setError(null);
        try {
            account && await AuthN.makeFetchRequest(
                instance,
                account,
                inProgress,
                scopes,
                callback
            );
        } catch (error: any) {
            setError(error.message || "Something went wrong");
        }
    }, [account, inProgress, instance]);
    return { isLoading, setIsLoading, error, consumeService };
}

export default useService;