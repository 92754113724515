import { faArrowRightLong, faCircleExclamation, faLeft, faReply, faRightLong } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValidInputTimeValue } from '@testing-library/user-event/dist/utils';
import moment from 'moment';
import React, { MouseEventHandler, useState } from 'react'
import useFocus from '../../../../hooks/use-focus';
import Comment, { CommentMessage } from '../Comment';
import './styles.scss';

export type CommentCollection = {
    /**
     * If there are more chat messages that can be retrieved, the next link will be populated.
     */
    nextLink: string;
    /**
     * Collection of chat messages.
     */
    value: CommentMessage[]
}

interface CommentThreadProps {
    threadId: string;
    comments: CommentCollection;
    onReplying?: MouseEventHandler;
    onReply?: (threadId: string, comment: string, event: React.MouseEvent<Element, MouseEvent>) => void;
}

const CommentThread = (props: CommentThreadProps & React.Attributes) => {
    const { threadId, comments } = props;
    const { onReplying, onReply } = props;

    const [isReplying, setIsReplying] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [hasError, setHasError] = useState(false);

    const [setFocus, focusProps] = useFocus(true);

    const onReplyingHandler: MouseEventHandler = event => {
        event.stopPropagation();
        if (onReplying) {
            setIsReplying(true);
            onReplying(event);
            setInputValue('');
        } else {
            setIsReplying(true);
        }
    }

    const onReplyHandler: MouseEventHandler = event => {
        event.stopPropagation();
        if (onReply) {
            try {
                onReply(threadId, inputValue, event);
                //throw new Error("Error whilst sending the comment.");

                setHasError(false);
                setIsReplying(false);
            } catch (error) {
                setHasError(true);
                console.log(error);
            } finally {
                setFocus(true);
            }
        }
    }

    const compareFunc = (a: CommentMessage, b: CommentMessage): number =>
        moment(a.createdOn).isBefore(moment(b.createdOn)) ? -1 : 1;

    const icon = hasError ? faCircleExclamation : faArrowRightLong;
    const classNameOnError = hasError ? 'error' : '';
    const classNameOnReplying = isReplying ? 'replying' : '';
    return (
        <div className={['comment-thread'].join(' ')}>
            {comments.value.sort(compareFunc).map(comment => (
                <Comment className={'comment'}
                    key={comment.id}
                    id={comment.id}
                    senderDisplayName={comment.senderDisplayname}
                    senderAvatarUrl={comment.senderAvatarUrl}
                    sequenceId={comment.sequenceId}
                    createdOn={comment.createdOn}
                    deletedOn={comment.deletedOn}
                    editedOn={comment.editedOn}
                    content={comment.content}
                />
            ))}
            <footer className={[classNameOnReplying, classNameOnError].join(' ')}>
                {isReplying && <input onChange={event => setInputValue(event.target.value)}
                    {...focusProps}
                    title='Send new comment'
                    placeholder='There is something in your mind about this?'
                />}
                <button onClick={isReplying ? onReplyHandler : onReplyingHandler}>
                    <FontAwesomeIcon icon={isReplying ? icon : faReply} />
                    <span>{isReplying ? 'Send' : 'Reply'}</span>
                </button>
            </footer>
        </div>
    )
}

export default CommentThread