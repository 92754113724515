import { faAngleDown, faAngleRight, faChartPie } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { IMenuItem } from '../../../../types/interfaces'
import styles from './VerticalMenuItem.module.css'

type Props = {
    menu?: IMenuItem,
    isCollapsed: boolean
}


const VerticalMenuItem = ({ menu: menuItem, isCollapsed }: Props) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleOpened = () => {
        setIsOpen((prevState) => !prevState);
    }

    const renderActiveClass = (isActive: boolean) => {
        setIsOpen(isActive);
        return isActive ? 'active' : '';
    }

    if (!menuItem) {
        return null
    }

    if (!menuItem.childMenuItems || menuItem.childMenuItems.length === 0) {
        return (
            <li className={styles['vertical-menu-item']}>
                {/* <FontAwesomeIcon className={styles.icon} icon={faChartPie} /> */}
                <NavLink className={({ isActive }) => renderActiveClass(isActive)}
                    to={`/analytics/${menuItem.feature?.id}`}
                    title={menuItem.name}
                >
                    {isCollapsed && <FontAwesomeIcon className={styles.icon} icon={faChartPie} />}
                    {!isCollapsed && menuItem.name}

                </NavLink>
            </li>);
    }

    return (
        <li className={styles['vertical-menu-item']}>
            <header onClick={toggleOpened} title={menuItem.name}>
                {

                    (<span>
                        {!isCollapsed && menuItem.name}
                    </span>)
                }
                <FontAwesomeIcon icon={isOpen ? faAngleRight : faAngleDown} />
            </header>

            <ul className={isOpen ? '' : styles.collapsed}>
                {menuItem.childMenuItems.map((child, index) => (
                    <VerticalMenuItem key={child.id} menu={child} isCollapsed={isCollapsed} />
                ))}
            </ul>
        </li>
    );
}

export default VerticalMenuItem