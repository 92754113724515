// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * @author Alex Alvarez Gárciga
 * 
 */


import { faCircleArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './DataReferenTOC.module.scss';

interface DataReferenTOCProps {

};

const DataReferenTOC = (props: DataReferenTOCProps) => {

    return (
        <aside className={["data-referen-toc", styles['data-referen-toc']].join(' ')}>
            <header>
                <h2>Data references</h2>
            </header>
            <ul>
                <li><a target="blank" href='https://florence.origin.cw/data-dictionary.pdf'><FontAwesomeIcon icon={faCircleArrowRight} />Data dictionary</a></li>
                <li><Link to='/'><FontAwesomeIcon icon={faCircleArrowRight} />Mortality and burden of disease <br /> outcome indicators</Link></li>
                <li><Link to='/'><FontAwesomeIcon icon={faCircleArrowRight} />NCD Risk Factor Indicators</Link></li>
                <li><Link to='/'><FontAwesomeIcon icon={faCircleArrowRight} />Data Classification Method</Link></li>
                <li><Link to='/data-references/trends-over-time'><FontAwesomeIcon icon={faCircleArrowRight} />Trends over time</Link></li>
                <li><Link to='/upload'><FontAwesomeIcon icon={faCircleArrowRight} />Upload File</Link></li>
            </ul>
        </aside>
    );
}

export default DataReferenTOC;