// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * @author username
 * 
 */

import React, { createContext, DetailedHTMLProps, InputHTMLAttributes, SelectHTMLAttributes, useContext, useState } from 'react';

import './styles.scss';
import styles from './FormGroup.module.scss';
import { FieldHookConfig, FieldInputProps, FieldMetaProps, useField } from 'formik';



interface FormGroupContext {
    name: string
    id?: string
    field?: FieldInputProps<string>
    meta?: FieldMetaProps<string>
}

const FormGroupContext = createContext<FormGroupContext>({
    name: '',

});

interface FormGroupProps {
    name: string
    children?: React.ReactNode
    id?: string
    className?: string
};

const FormGroup = ({ className, children, ...props }: FormGroupProps
    & FieldHookConfig<string>
    & (
        DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
        | DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>
    )

) => {
    const [name, setName] = useState(props.name)
    const [id, setId] = useState(props.id)
    const [field, meta] = useField(props)

    return (
        <FormGroupContext.Provider value={{
            name,
            id,
            field,
            meta
        }}>
            <div
                className={['form-group', styles['form-group'], className].join(' ')}>
                {children}
            </div>
        </FormGroupContext.Provider>
    );
}

interface LabelProps {
    children?: React.ReactNode
    className?: string
}
const Label = ({ className, children, ...props }: LabelProps) => {
    const { name, id } = useContext(FormGroupContext);

    return (
        <label htmlFor={id || name} {...props}>
            {children}
        </label>
    )
}

interface InputProps {
    className?: string
}

const Input = ({ className, ...props }: InputProps
    & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {
    const { name, id, meta, field } = useContext(FormGroupContext);
    const errorClassName = meta?.touched && meta?.error ? "error" : ""
    return (
        <input
            id={id || name}
            className={['form-group-input', className, errorClassName].join(' ')}
            {...props}
            {...field}
        />
    )
}

interface DropdownProps {
    className?: string
    children?: React.ReactNode;
}

const Dropdown = ({ className, children, ...props }: DropdownProps
    & DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>) => {
    const { name, id, meta, field } = useContext(FormGroupContext);
    const errorClassName = meta?.touched && meta?.error ? "error" : ""
    return (
        <select
            id={id || name}
            className={['form-group-select', className, errorClassName].join(' ')}
            {...props}
            {...field}
        >
            {children}
        </select>
    )
}

interface ErrorMessageProps {
    children?: (state: FieldMetaProps<string>) => JSX.Element
    className?: string
}

const ErrorMessage = ({ className, ...props }: ErrorMessageProps) => {
    const { meta } = useContext(FormGroupContext);

    return (
        meta?.touched && meta?.error
            ? (
                <span className={['error-message', className].join(' ')}>
                    {props.children ? props.children?.(meta) : meta.error}
                </span>
            )
            : null
    )
}

FormGroup.Label = Label;
FormGroup.Input = Input;
FormGroup.Dropdown = Dropdown;
FormGroup.ErrorMessage = ErrorMessage;

export default FormGroup;