// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * @author Alex Alvarez Gárciga
 * 
 */

import { ObjectSchema } from "yup";
import { protectedWebApi } from "../auth";
import { Strings } from "../constants";
import { AuthZ } from "../library/security";
import UserModel from "../models/UserModel";
import { ResponseDTO } from "../types/dto";
import { LoosedObjectProps } from "../types/global";
import { User } from "../types/interfaces";

export type UserDTO = {
    active: boolean; // active boolean nullable: true
    communicationUserId: string; // communicationUserId	string nullable: true
    displayName: string; // displayName	string nullable: true
    givenName: string; // givenName	string nullable: true
    id: string; // id string($uuid)
    incaUserId: string; // incaUserId string($uuid) nullable: true
    mail: string; // mail string nullable: true
    mobilePhone: string; // mobilePhone	string nullable: true
    preferredLanguage: string; // preferredLanguage	string nullable: true
    surname: string; // surname	string nullable: true
    userPrincipalName: string; // userPrincipalName	string nullable: true
    roles?: string[]
};


const UserService = {
    getUsers: async (searchPredicate?: string, accessToken?: string) => {
        const endpointUrl = protectedWebApi.b2c.apiUser.incaUsers.endpoint;
        if (!accessToken) {
            throw new Error("Access token is required");
        }
        try {
            const result = await AuthZ.fetch<ResponseDTO<UserDTO[]>>(accessToken,
                `${endpointUrl}/${searchPredicate}`);
            if (result instanceof Response) {
                // return result;
                throw new Error(`${result.status} ${result.statusText}`);
            } else {
                console.log("UserService.getUsers()");
                console.table(result)
                return result.value.map((user: UserDTO, index) => new UserModel(
                    user.id,
                    user.active,
                    user.mail,
                    user.givenName,
                    user.surname,
                    user.displayName,
                    user.userPrincipalName,
                    user.mobilePhone,
                    user.preferredLanguage,
                    user.roles,
                    user.incaUserId,
                    user.communicationUserId,
                    // user.avatar
                ));
            }
            //console.table(result.value);
            //throw new Error('Error Testing');


        } catch (error) {
            return Promise.reject(error);
        }
    },
    getUser: async (id: string, accessToken?: string) => {
        const endpointUrl = protectedWebApi.b2c.apiUser.incaUsers.endpoint;
        if (!accessToken) {
            throw new Error("Access token is required");
        }
        try {
            const result = await AuthZ.fetch<ResponseDTO<UserDTO>>(accessToken,
                `${endpointUrl}/${id}`);

            if (result instanceof Response) {
                // return result
                throw new Error(`${result.status} ${result.statusText}`);

            } else {
                console.log("UserService.getUser()");
                console.log(result.value);
                return new UserModel(
                    result.value.id,
                    result.value.active,
                    result.value.mail,
                    result.value.givenName,
                    result.value.surname,
                    result.value.displayName,
                    result.value.userPrincipalName,
                    result.value.mobilePhone,
                    result.value.preferredLanguage,
                    result.value.roles,
                    result.value.incaUserId,
                    result.value.communicationUserId
                )
            }
        } catch (error) {
            return Promise.reject(error)
        }
    },
    getUserByEmail: async (mail: string, accessToken?: string) => {
        const endpointUrl = protectedWebApi.b2c.apiUser.incaUsers.endpoint;
        if (!accessToken) {
            throw new Error("Access token is required");
        }
        try {
            const result = await AuthZ.fetch<ResponseDTO<UserDTO[]>>(accessToken,
                `${endpointUrl}/?$filter=mail eq '${mail}'`);
            if (result instanceof Response) {
                // return result
                throw new Error(`${result.status} ${result.statusText}`);
            } else {
                console.log("UserService.getUserByEmail()");
                console.log(result);
                const userDTO = result.value[0] as UserDTO
                return userDTO
                    ? new UserModel(
                        userDTO.id,
                        userDTO.active,
                        userDTO.mail,
                        userDTO.givenName,
                        userDTO.surname,
                        userDTO.displayName,
                        userDTO.userPrincipalName,
                        userDTO.mobilePhone,
                        userDTO.preferredLanguage,
                        userDTO.roles,
                        userDTO.incaUserId,
                        userDTO.communicationUserId
                    )
                    : null
            }
        } catch (error) {
            return Promise.reject(error)
        }
    },
    create: async (user: UserModel, accessToken?: string) => {
        const endpointUrl = protectedWebApi.b2c.apiUser.incaUsers.endpoint;
        if (!accessToken) {
            throw new Error(Strings.errors.noAccessToken);
        }
        try {
            const data: UserDTO = {
                // id: null,
                active: true,
                mail: user.mail,
                givenName: user.givenName || null,
                surname: user.surname || null,
                displayName: user.displayName || null,
                preferredLanguage: user.preferredLanguage || null,
                mobilePhone: user.mobilePhone || null,
                userPrincipalName: user.mail || null,
                roles: user.roles || null
            } as UserDTO;

            const result = await AuthZ.fetch<any>(accessToken, endpointUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (result instanceof Response) {
                // return result
                throw new Error(`${result.status} ${result.statusText}`);
            } else {
                console.log("UserService.create()");
                console.table(result);
                return result;
            }
        } catch (error) {
            return Promise.reject(error);
        }
    },
    update: async (user: UserModel, accessToken?: string) => {

        const endpointUrl = protectedWebApi.b2c.apiUser.incaUsers.endpoint;
        if (!accessToken) {
            throw new Error(Strings.errors.noAccessToken);
        }
        try {

            const data: UserDTO = {
                id: user.id,
                active: user.active || null,
                mail: user.mail || null,
                givenName: user.givenName || null,
                surname: user.surname || null,
                displayName: user.displayName || null,
                preferredLanguage: user.preferredLanguage || null,
                mobilePhone: user.mobilePhone || null,
                userPrincipalName: user.mail || null,
                roles: user.roles || null
            } as UserDTO;

            const result = await AuthZ.fetch<unknown>(accessToken,
                `${endpointUrl}/${user.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })

            if (result instanceof Response) {
                // return result
                throw new Error(`${result.status} ${result.statusText}`);
            } else {
                console.log("UserService.update()");
                console.table(result)
                return result;
            }
        } catch (error) {
            return Promise.reject(error)
        }
    },
    setActive: async (id: string, active: boolean, accessToken?: string) => {
        const endpointUrl = protectedWebApi.b2c.apiUser.incaUsers.endpoint;
        if (!accessToken) {
            throw new Error(Strings.errors.noAccessToken);
        }
        try {
            const result = await AuthZ.fetch<unknown>(accessToken,
                `${endpointUrl}/${id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    active: active
                })
            })

            if (result instanceof Response) {
                // return result
                throw new Error(`${result.status} ${result.statusText}`);
            } else {
                console.log("UserService.setActive()");
                console.table(result)
                return result;
            }
        } catch (error) {
            return Promise.reject(error)
        }
    }
}

export default UserService;