// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * @author Alex Alvarez Gárciga
 * 
 */

import { FieldHookConfig, useField } from 'formik';
import { DetailedHTMLProps, InputHTMLAttributes } from 'react';

import './styles.scss';
import styles from './TextBox.module.scss';

interface TextBoxProps {
    label?: string;
    id?: string;
};

// const TextBox = ({ label, ...props }: TextBoxProps & FieldHookConfig<string>) => {
/**
 * 
 * @param param0 
 * @returns 
 * @example
 * 
 * <TextBox
 *   label="Email Address"
 *   name="email"
 *   type="email"
 *   placeholder="jane@formik.com"
 *   />
 */
// const TextBox = ({ id, label, ...props }: TextBoxProps & FieldInputProps<string>) => {
const TextBox = ({ id, label, ...props }: TextBoxProps
    & FieldHookConfig<string>
    & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {

    const [field, meta] = useField(props);
    return (
        <>
            {label && <label htmlFor={id || props.name}>{label}</label>}
            <input className={['text-box', styles['text-box'], meta.touched && meta.error ? "error" : ""].join(' ')}
                {...props}
                {...field}
                id={id || props.name}
            />
            {meta.touched && meta.error ? (
                <span className={['text-box-error', styles['text-box-error']].join(' ')}>{meta.error}</span>
            ) : null}
        </>
    );
}

export default TextBox; 