import { faMessageArrowDown, faMessageArrowUp, faMessageQuote } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEventHandler, FormEventHandler, MouseEventHandler, useState } from 'react';
import CommentThread, { CommentCollection } from '../CommentThread';
import './styles.scss';

export type CommnetThreadObject = {
    id: string;
    comments: CommentCollection;
}

export type CommentThreadCollection = {
    nextLink: string;
    value: CommnetThreadObject[];
}

export type ReplayingThreadHandler = (threadId: string, comment: string, event: React.MouseEvent<Element, MouseEvent>) => void;

interface CommentBoxProps {
    subject: string;
    placeHolder: string;
    commentThreads: CommentThreadCollection;
    onPost?: MouseEventHandler;
    onSubmit?: FormEventHandler;
    value: string;
    onChange: ChangeEventHandler<HTMLTextAreaElement>;
    onReplying?: MouseEventHandler;
    onReply?: ReplayingThreadHandler;
}

const CommentBox = (props: CommentBoxProps) => {
    const { subject, value } = props;
    const { placeHolder } = props;
    const { commentThreads } = props;
    const { onPost, onSubmit, onChange } = props;
    const { onReplying, onReply } = props;

    const onSubmitHandler: FormEventHandler = (event) => {
        event.preventDefault();
        if (onSubmit) {
            onSubmit(event);
        }
    }

    const onPostButtonHandler: MouseEventHandler = (event) => {
        event.stopPropagation();
        if (onPost) {
            onPost(event);
        }

    }

    return (
        <div className={['comment-box'].join(' ')}>
            <header>
                <h2>
                    <FontAwesomeIcon icon={faMessageQuote} />
                    <span>Comments</span>
                </h2>
                <h3>{subject}</h3>
            </header>
            <form action="" onSubmit={onSubmitHandler}>
                <label htmlFor="commentsInput" aria-label='Comment Input'>
                    <textarea
                        id="commentsInput"
                        placeholder={placeHolder}
                        value={value}
                        onChange={onChange}
                    />
                </label>
                <footer>
                    <div>
                        <button>
                            <FontAwesomeIcon icon={faMessageArrowUp} />
                            <span>Previous</span>
                        </button>
                        <button>
                            <FontAwesomeIcon icon={faMessageArrowDown} />
                            <span>Next</span>
                        </button>
                    </div>
                    <button onClick={onPostButtonHandler} disabled={value ? false : true}>
                        <span>Post</span>
                    </button>
                </footer>
            </form>
            <div className={['comment-box-content'].join(' ')}>
                {
                    (!commentThreads || !commentThreads.value || commentThreads.value.length === 0)
                    && <span>No Comments</span>
                }
                {commentThreads && commentThreads.value && commentThreads.value.length > 0 && commentThreads.value.map(thread => (
                    <CommentThread
                        key={thread.id}
                        threadId={thread.id}
                        comments={thread.comments}
                        onReply={onReply}
                        onReplying={onReplying}
                    />
                ))}
            </div>
        </div>
    )
}

export default CommentBox