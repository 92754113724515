// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * @name AdministrationUsersScreen
 * @namespace screens
 * @module Administration
 * @author Alex Alvarez Gárciga
 * 
 */

import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid, { Column, SelectCellFormatter, SelectColumn, SortColumn } from 'react-data-grid';
import { Link, useNavigate } from 'react-router-dom';
import { protectedWebApi } from '../../../auth';
import { Avatar, Button, Dialog, Search } from '../../../components/customs';
import { AppTitle } from '../../../components/layouts';
import { Strings } from '../../../constants';
import { compareTwoNullableStrins, compareTwoStrings } from '../../../functions';
import { useFetchState } from '../../../hooks';
import useReactDataGridSort from '../../../hooks/useReactDataGridSort';
import UserModel from '../../../models/UserModel';
import UserService from '../../../services/UserService';
import { Comparator } from '../../../types/global';

import styles from './AdministrationUsersScreen.module.scss';

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/formatToParts
 */
// const dateFormatter = new Intl.DateTimeFormat(navigator.language, Formats.dateTime.intl.dateTime);

// type TimestampFormatterprops = {
//     timestamp: number;
// }
// const TimestampFormatter = ({ timestamp }: TimestampFormatterprops) => (<>{dateFormatter.format(timestamp)}</>)

interface UsersTableSummaryRow {
    id: string;
    totalCount: number;
    activeCount: number;
}

const getUserTableModelComparator = (sortColumn: string): Comparator<UserModel> => {
    switch (sortColumn) {
        case 'displayName':
            return (a, b) => compareTwoStrings(a[sortColumn], b[sortColumn]);
        case 'userPrincipalName':
        case 'mail':
            return (a, b) => compareTwoNullableStrins(a[sortColumn], b[sortColumn]);
        case 'active':
            return (a, b) => Number(a.active) - Number(b.active);
        // case 'createdDateTime':
        //     return (a, b) => {
        //         return new Date(a[sortColumn]).getTime() - new Date(b[sortColumn]).getTime();
        //     };
        default:
            throw new Error(`Unsupported sortColumn: "${sortColumn}"`);
    }
}

const getUserTableColumnsConfig = (): readonly Column<UserModel, UsersTableSummaryRow>[] => [
    SelectColumn,
    {
        key: 'avatar',
        name: 'Avatar',
        width: 80,
        frozen: true,
        formatter: ({ row }) => {
            return (
                <Avatar name={row.displayName} />
            )
        },
        summaryFormatter: () => {
            return (<strong>Total</strong>)
        }
    },
    {
        key: 'displayName',
        name: 'Name',
        frozen: true,
        width: 250,
        summaryFormatter: ({ row }) => {
            return (<>{`${row.totalCount} user(s)`}</>)
        }
    },
    { key: 'mail', name: 'Email' },
    { key: 'userPrincipalName', name: 'User name' },
    {
        key: 'active',
        name: 'Enabled',
        width: 100,
        formatter: ({ row, onRowChange, isCellSelected }) => {
            return (
                <SelectCellFormatter
                    value={row.active}
                    disabled
                    onChange={() => {
                        row.active = !row.active;
                        onRowChange(row)
                    }}
                    isCellSelected={isCellSelected}
                />
            );
        },
        summaryFormatter: ({ row }) => {
            return (<>{`${row.activeCount} active(s)`}</>)
        }
    },
    // {
    //     key: 'createdDateTime', name: 'Created',
    //     formatter(formatterProps) {
    //         const date = new Date(formatterProps.row.createdDateTime);
    //         return <TimestampFormatter timestamp={date.getTime()} />
    //     }
    // },
    {
        key: 'action',
        name: "",
        sortable: false,
        width: 80,
        formatter: ({ row }) => {
            return (
                <Link className='datagrid-action' to={`./${row.id}/edit`}>
                    <FontAwesomeIcon icon={faEdit} />
                </Link>
            )
        }
    } as Column<any>
];


interface AdministrationUsersScreenProps { }

const AdministrationUsersScreen = ({ ...props }: AdministrationUsersScreenProps) => {

    const title = 'Users | Administration';
    const navigate = useNavigate();

    const {
        state: fetchState,
        setLoading: fetchSetLoading,
        setError: fetchSetError,
        setClear: fetchSetClear
    } = useFetchState();

    const [rows, setRows] = useState<UserModel[]>([]);
    const [selectedRows, setSelectedRows] = useState<ReadonlySet<string>>(() => new Set());
    const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
    const [showErrorDialog, setShowErrorDialog] = useState(false);

    const sortedRows = useReactDataGridSort<UserModel>(getUserTableModelComparator, rows, sortColumns);

    const columns: readonly Column<UserModel, UsersTableSummaryRow>[] = useMemo(() =>
        getUserTableColumnsConfig(), []);

    const summaryRows = useMemo(() => {
        const summaryRow: UsersTableSummaryRow = {
            id: 'total_summary',
            totalCount: rows.length,
            activeCount: rows.filter((r) => r.active).length
        };
        return [summaryRow];
    }, [rows]);

    const filterHandler = useCallback((data: UserModel[]) => {
        setRows(data);
    }, []);

    useEffect(() => {
        if (fetchState.error) {
            setShowErrorDialog(true);
        } else {
            setShowErrorDialog(false);
        }
    }, [fetchState]);

    const showErrorDialogHandler = () => {
        setShowErrorDialog(true);
    }


    const closeErrorDialogHanlder = () => {
        fetchSetClear()
    };

    // const addUserHandler = (user: UserModel) => {
    //     dispatchFetch({
    //         type: FetchStateActionType.request
    //     });

    //     // TODO: when you have the response 
    //     // // dispatchFetch({
    //     // //     type: FetchStateActionType.response
    //     // // });
    // }

    // const dateFormatter = new Intl.DateTimeFormat(navigator.language);

    // TODO: Example on how to distpatch an event 
    // useEffect(() => {
    //     requestAnimationFrame(() => {
    //         // const event = window.document.createEvent('UIEvents');
    //         // event.initUIEvent('resize', true, false, window, 0);
    //         // window.dispatchEvent(event);

    //         const event = new Event('resize');
    //         window.addEventListener('resize', (event) => {/* ... */ }, false);
    //         window.dispatchEvent(event);
    //     })

    // }, [])


    return (
        <>
            <AppTitle title={`${title} | ${Strings.branding.logo} `} />
            <Dialog isVisible={showErrorDialog} closeModal={closeErrorDialogHanlder}
                title={'Error Dialog'}
                footer={
                    <>
                        <Button onClick={closeErrorDialogHanlder}>Close</Button>
                    </>
                }>

                <p>{fetchState?.error}</p>
            </Dialog>

            <header className={["screen-header", styles["screen-header"]].join(" ")}>

                <h3>Users</h3>

                <Button primary onClick={() => {
                    navigate("new");
                }}>
                    New
                </Button>
            </header>

            <div className={styles["screen-content"]}>
                <div className={styles["datagrid-header"]}>

                    <Search field='displayName'
                        title='Search by name'
                        label='Search'
                        placeholder='name'
                        scopes={protectedWebApi.b2c.apiUser.incaUsers.scopes}
                        serviceEndpointHandler={UserService.getUsers}
                        onFetch={filterHandler}
                        setError={fetchSetError}
                        setLoading={fetchSetLoading}
                    />

                </div>
                {rows.length
                    ? !fetchState.loading &&
                    <DataGrid className={[styles.datagrid, 'rdg-light'].join(' ')}
                        headerRowHeight={40}
                        rowHeight={40}
                        defaultColumnOptions={{
                            sortable: true,
                            resizable: true
                        }}
                        columns={columns}
                        rows={sortedRows}
                        summaryRows={summaryRows}
                        rowKeyGetter={(row) => row.id!}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={setSelectedRows}
                        cellNavigationMode={'CHANGE_ROW'}
                        sortColumns={sortColumns}
                        onSortColumnsChange={setSortColumns}

                    />
                    : fetchState.loading ? <p>Retreiving records</p> : <p>No records</p>
                }
            </div>
        </>
    );
}

export default AdministrationUsersScreen;