// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * 
 * @author username
 * 
 */

import React from 'react';

import './styles.scss';
import styles from './Dialog.module.scss';
import { createPortal } from 'react-dom';
import Backdrop from '../../layouts/Backdrop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';

interface DialogProps {
    children?: React.ReactNode;
    footer?: React.ReactNode;
    isVisible: boolean;
    title?: string;
    closeModal: () => void;
};

const rootElement = document.getElementById('root');

/**
 * A dialog is a window overlaid on either the primary window or another dialog window.
 * @param props 
 * @returns 
 */
const Dialog = (props: DialogProps) => {
    const { title, children, footer } = props;
    const { isVisible, closeModal } = props;
    const onClickHandler = () => {
        closeModal();
    }
    return rootElement && createPortal(
        <>
            <Backdrop isVisible={isVisible} />
            <div className={['dialog', styles['dialog'], isVisible ? 'open' : ''].join(' ')}
                role="dialog"
                aria-labelledby="dialog"
                aria-modal="true"
            >
                <header>
                    <h2>{title}</h2>
                    <FontAwesomeIcon className='dialog-close' icon={faCircleXmark} onClick={onClickHandler} />
                </header>
                <div className='dialog-body'>

                    {children}
                </div>
                <footer>{footer}</footer>
            </div>
        </>
        , rootElement);
}

export default Dialog;