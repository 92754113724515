const compareTwoNullableStrins = (a?: string, b?: string) => {
    if (!a && !b) {
        return 0;
    } else if (a && b) {
        const value1: string = a || '';
        const value2: string = b || '';
        return value1.localeCompare(value2)
    } else if (!a) {
        return -1;
    }
    return 1;
}

export default compareTwoNullableStrins;


// if (!a[sortColumn] && !b[sortColumn]) {
//     return 0;
// } else if (a[sortColumn] && b[sortColumn]) {
//     const value1: string = a[sortColumn] || '';
//     const value2: string = b[sortColumn] || '';
//     return value1.localeCompare(value2)
// } else if (!a[sortColumn]) {
//     return -1;
// }
// return 1;