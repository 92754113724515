// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * @author Alex Alvarez Gárciga
 * 
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid, { Column, SelectColumn, SortColumn } from 'react-data-grid';
import { protectedWebApi } from '../../../auth';
import { compareTwoStrings } from '../../../functions';
import { useFetchState } from '../../../hooks';
import useReactDataGridSort from '../../../hooks/useReactDataGridSort';
import PermissionModel from '../../../models/PermissionModel';
import PermissionService from '../../../services/PermissionService';
import { Comparator } from '../../../types/global';
import { Button, Dialog, Search } from '../../customs';

import styles from './PermissionTable.module.scss';

interface PermissionTableSummaryRow {
    id: string;
    totalCount: number;
}



const getPermissionTableModelComparator = (column: string): Comparator<PermissionModel> => {
    switch (column) {
        case 'featureName':
        case 'description':
        case 'permissionKey':
            return (a, b) => compareTwoStrings(a[column], b[column])
        default:
            throw new Error(`Unsupported sort on column: "${column}"`)
    }
}

const getPermissionTableColumnsConfig = (): readonly Column<PermissionModel, PermissionTableSummaryRow>[] => [
    SelectColumn,
    {
        key: 'permissionKey',
        name: 'Permission',
        summaryFormatter: ({ row }) => (<>{`${row.totalCount} permissions(s)`}</>)
    },
    {
        key: 'featureName',
        name: 'Resource'
    },
    {
        key: 'description',
        name: 'Description',
        summaryFormatter: () => (<strong>Total</strong>)
    },
];

interface PermissionTableProps {
    className?: string
    datagridClassName?: string
};

// Lifting state
const PermissionTable = (props: PermissionTableProps) => {
    const { className, datagridClassName } = props;
    const {
        state: fetchState,
        setLoading: fetchSetLoading,
        setError: fetchSetError,
        setClear: fetchSetClear
    } = useFetchState();

    const [rows, setRows] = useState<PermissionModel[]>([]);
    const [selectedRows, setSelectedRows] = useState<ReadonlySet<string>>(() => new Set());
    const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
    const [showErrorDialog, setShowErrorDialog] = useState(false);

    const sortedRows = useReactDataGridSort<PermissionModel>(getPermissionTableModelComparator, rows, sortColumns);
    const columns: readonly Column<PermissionModel, PermissionTableSummaryRow>[] = useMemo(() =>
        getPermissionTableColumnsConfig(), []);

    const summaryRows = useMemo(() => {
        const summaryRow: PermissionTableSummaryRow = {
            id: 'total_summary',
            totalCount: rows.length
        };
        return [summaryRow];
    }, [rows]);

    const filterHandler = useCallback((data: PermissionModel[]) => {
        setRows(data);
    }, []);

    useEffect(() => {
        if (fetchState.error) {
            setShowErrorDialog(true);
        } else {
            setShowErrorDialog(false);
        }
    }, [fetchState]);

    const closeErrorDialogHanlder = () => {
        fetchSetClear()
    };

    return (
        <div className={[styles['permission-table'], className].join(' ')}>
            <Dialog isVisible={showErrorDialog} closeModal={closeErrorDialogHanlder}
                title={'Error Dialog'}
                footer={
                    <>
                        <Button onClick={closeErrorDialogHanlder}>Close</Button>
                    </>
                }>

                <p>{fetchState?.error}</p>
            </Dialog>

            <div className={styles["datagrid-header"]}>
                <Search field='description'
                    title='Search by description'
                    label='Search'
                    placeholder='description'

                    scopes={protectedWebApi.b2c.apiPermissions.incaPermissions.scopes}
                    serviceEndpointHandler={PermissionService.getPermissions}
                    onFetch={filterHandler}
                    setError={fetchSetError}
                    setLoading={fetchSetLoading}
                />
            </div>

            {
                rows.length
                    ? !fetchState.loading &&
                    <DataGrid className={[styles.datagrid, 'rdg-light', datagridClassName].join(' ')}
                        headerRowHeight={40}
                        defaultColumnOptions={{
                            sortable: true,
                            resizable: true
                        }}
                        columns={columns}
                        rows={sortedRows}
                        summaryRows={summaryRows}
                        rowKeyGetter={(row) => row.id}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={setSelectedRows}
                        cellNavigationMode={'CHANGE_ROW'}
                        sortColumns={sortColumns}
                        onSortColumnsChange={setSortColumns}

                    />
                    : fetchState.loading ? <p>Retreiving records</p> : <p>No records</p>
            }

        </div>
    );
}

export default PermissionTable;