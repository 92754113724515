// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * @author username
 * 
 */

import React, { CSSProperties, DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import hexColor from 'hexadecimal-color';

import './styles.scss';
import styles from './Avatar.module.scss';

import initials from 'initials';

const defaultColors = [
    '#2ecc71', // emerald
    '#3498db', // peter river
    '#8e44ad', // wisteria
    '#e67e22', // carrot
    '#e74c3c', // alizarin
    '#1abc9c', // turquoise
    '#2c3e50', // midnight blue
];

function sumChars(str: string) {
    let result = 0;
    for (let i = 0; i < str.length; i++) {
        result += str.charCodeAt(i);
    }
    return result;
}

interface AvatarProps {
    name: string;

    borderRadius?: number | string;
    color?: string;
    colors?: string[];
    size?: number;
    className?: string;
    src?: string;
    srcSet?: string;
};

const Avatar = ({
    size = 32,
    colors = defaultColors,
    borderRadius = '50%',
    name,
    src,
    srcSet,
    color,
    className,
    ...props
}: AvatarProps & DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {

    if (!name) {
        throw new Error("Avatar requires a name");
    }

    const abbr = initials(name);
    const sizeValue = `${size.toString(10)}px`;

    let backgroundColor;
    if (color) {
        backgroundColor = color;
    } else {
        let index = sumChars(name) % colors.length;
        backgroundColor = colors[index];
    }
    const style: CSSProperties = {
        borderRadius: borderRadius,
        width: sizeValue,
        height: sizeValue,
        maxWidth: sizeValue,
        maxHeight: sizeValue,
        lineHeight: sizeValue,
        textAlign: 'center',
        backgroundColor: backgroundColor
    };

    let inner;
    if (src || srcSet) {

        inner = <img src={src} srcSet={srcSet} alt={name} style={style} width={size} height={size} {...props} />
    } else {
        inner = <span style={style}>{abbr}</span>;
    }

    // const theme = contrast(backgroundColor);
    const theme = hexColor.contrast(backgroundColor);

    return (
        <div
            aria-label={name}
            role='img'
            className={['avatar', theme, styles['avatar'], styles[theme], className,].join(' ')}
            style={style}>
            {inner}
        </div>
    );
}

export default Avatar;