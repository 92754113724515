import React, { FC, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Strings } from '../../../constants';
import { GenericErrorHandler } from '../../../errors';
import Header from '../../customs/Header';

import Logo from '../../views/Logo';

import styles from './DefaultLayout.module.css';

import { EndSessionPopupRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { faBars, faRightFromBracket, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mockMenuEntries from '../../../data/main-menu.json';
import { getFontAwesomeIcon } from '../../../functions';
import Navigation, { NavigationEntry } from '../../customs/Navigation';


interface DefaultLayoutProps {
    children: React.ReactNode;
}

const buildMenuEntries = (entries: any[]): NavigationEntry[] => (
    entries.map(entry => (
        {
            type: entry.type,
            label: entry.label,
            icon: getFontAwesomeIcon(entry.icon),
            link: entry.type === 'anchor' ? entry.link : ''
        }
    )) as NavigationEntry[]
)

const DefaultLayout: FC<DefaultLayoutProps> = ({ children }: DefaultLayoutProps) => {

    const { accounts, instance } = useMsal();
    const name = accounts && accounts[0] && accounts[0].name;
    const username = accounts && accounts[0] && accounts[0].username;

    const [menuEntries, setMenuEntries] = React.useState<Array<NavigationEntry>>([]);

    useEffect(() => {
        // Alex: main-menu.json needs to be in public folder in order to work.
        // fetch('main-menu.json', {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json'
        //     }
        // })
        //     .then(response => response.json())
        //     .then(data => {
        //         const menuEntries = buildMenuEntries(data);
        //         menuEntries.splice(menuEntries.length, 0, {
        //             type: 'span',
        //             label: name || '',
        //             icon: <FontAwesomeIcon icon={faUser} />
        //         });
        //         menuEntries.splice(menuEntries.length, 0, {
        //             type: 'button',
        //             label: 'Sign out',
        //             icon: <FontAwesomeIcon icon={faRightFromBracket} />,
        //             onClick: (event) => {
        //                 instance.logoutRedirect({
        //                     mainWindowRedirectUri: '/'
        //                 } as EndSessionPopupRequest);
        //             }
        //         });
        //         setMenuEntries(menuEntries);
        //     })



        const menuEntries = buildMenuEntries(mockMenuEntries);
        menuEntries.splice(menuEntries.length, 0, {
            type: 'span',
            label: name || '',
            icon: <FontAwesomeIcon icon={faUser} />
        });
        menuEntries.splice(menuEntries.length, 0, {
            type: 'button',
            label: 'Sign out',
            icon: <FontAwesomeIcon icon={faRightFromBracket} />,
            onClick: (event) => {
                instance.logoutRedirect({
                    mainWindowRedirectUri: '/'
                } as EndSessionPopupRequest);
            }
        });
        setMenuEntries(menuEntries);
    }, []);

    return (
        <>
            <Header
                className={styles.header}
                logo={<Logo text={Strings.branding.logo} />}
                navigation={<Navigation
                    entries={menuEntries}
                    collapsedIcon={<FontAwesomeIcon icon={faBars} />}
                    direction='horizontal'
                />}
            />
            <ErrorBoundary FallbackComponent={GenericErrorHandler}>
                <main>
                    {children}
                </main>
            </ErrorBoundary>
        </>
    )
}

export default DefaultLayout