export const Videos = {
    grass: require('../assets/videos/grass.mp4'),
}

export const Images = {
    logo: require('../assets/images/gmn-logo.jpg'),
    //logo: require('../assets/images/szv-logo.png'),
}


const Assets = {
    videos: Videos,
    images: Images
}

export default Assets;