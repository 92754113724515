
// import { faUser, faUsers } from "@fortawesome/pro-solid-svg-icons";
import { faUser, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, Outlet } from "react-router-dom";
import { DefaultLayout } from "../../components/layouts";
import AsideView from "../../components/layouts/AsideView";
import styles from "./Administration.module.scss";
interface AdministrationProps {

};

const AdministrationScreen = (props: AdministrationProps) => {
    return (
        <DefaultLayout>
            <AsideView title="Administration">
                <StaticAdministrationMenu />
                <Outlet />
            </AsideView>
        </DefaultLayout>
    )
}

interface StaticAdministrationMenuProps {
    isCollapsed?: boolean;
}

const StaticAdministrationMenu = ({ isCollapsed }: StaticAdministrationMenuProps) => {
    return (
        <ul className={["administration-menu", styles['menu'], isCollapsed ? styles['collapsed'] : ''].join(' ')}>
            <li>
                <NavLink className={(a) => a.isActive ? styles.active : ''} to={'users'}><FontAwesomeIcon icon={faUsers} /> <span>Users</span> </NavLink>
            </li>
            <li>
                <NavLink className={(a) => a.isActive ? styles.active : ''} to={'roles'}><FontAwesomeIcon icon={faUser} /><span>Roles</span></NavLink>
            </li>
        </ul>
    );
}

export default AdministrationScreen;
export { default as AdministrationUsersScreen } from './AdministrationUsersScreen';
export { default as AdministrationUserNewScreen } from './AdministrationUserNewScreen';
export { default as AdministrationRolesScreen } from './AdministrationRolesScreen';
export { default as AdministrationRoleNewScreen } from './AdministrationRoleNewScreen';