import { protectedWebApi } from '../../auth';
import Dataset from '../../components/containers/Dataset';
import { DefaultLayout, SplitView } from '../../components/layouts';
import AppTitle from '../../components/layouts/AppTitle';
import ThreeDots from '../../components/ui/Glimmers/TreeDots';
import CardMenu from '../../components/ui/Menu/CardMenu';
import DataReferenTOC from '../../components/views/DataReferenTOC';
import { Strings } from '../../constants';
import MenuService from '../../services/MenuService';

import styles from './InsightsScreen.module.css';

type InsightsScreenProps = {

}

const InsightsScreen = ({ }: InsightsScreenProps) => {

    // const { accounts } = useMsal();
    // const username = accounts && accounts[0] && accounts[0].username;
    // const name = accounts && accounts[0] && accounts[0].name;

    return (
        <DefaultLayout>
            <AppTitle title={`${Strings.branding.logo} | Insights`} />

            <div className={styles.dashboard}>
                <header>
                    <h2>Insights</h2>
                    <p>Discover the potential of your existing data by visualizing meaningful dashboards based on health and social indicators.</p>
                    <p>These insights will help you recognize and understand patterns and trends in health. Insights also generate new knowledge and support evidence-based decision-making to improve the overall health and well-being of our population.</p>
                </header>
                <SplitView
                    leftWeight={5}
                    rightWeight={1}
                    rightChildClass="insightRightColumn"
                >
                    <Dataset
                        resourceName='menu'
                        scopes={protectedWebApi.b2c.apiMenu.menuItems.scopes}
                        service={MenuService.getMenuItems}
                    >
                        <CardMenu loader={
                            <ThreeDots style={{ alignSelf: 'center' }} />
                        } key={'card-menu'} />
                    </Dataset>

                    <DataReferenTOC />
                </SplitView>

            </div>
        </DefaultLayout>
    )
}

export default InsightsScreen