import React from 'react';
import styles from './Comment.module.scss';
import moment from 'moment'
import './styles.scss';
import Avatar from '../../Avatar';

type CommentParticipant = {
    /**
     * 	CommunicationIdentifierModel
     */
    communicationIdentifier: string;
    /**
     * Display name for the chat participant.
     */
    displayName: string;
    /**
     * Time from which the comment history is shared with the participant. 
     * The timestamp is in RFC3339 format: yyyy-MM-ddTHH:mm:ssZ.
     */
    shareHistoryTime: string;
}

type CommentContent = {
    /**
     * Comment message content for messages of types text or html.
     */
    message: string;
}



interface CommentProps {
    /**
     * The id of the chat message. This id is server generated.
     */
    id: string;
    /**
     * The display name of the chat message sender. 
     * This property is used to populate sender name for push notifications.
     */
    senderDisplayName: string;
    /**
     * The avatar url of the chat message sender.
     */
    senderAvatarUrl?: string;
    /**
     * Sequence of the chat message in the conversation.
     */
    sequenceId: number | string;
    /**
     * The timestamp when the chat message arrived at the server. 
     * The timestamp is in RFC3339 format: yyyy-MM-ddTHH:mm:ssZ.
     */
    createdOn: string | Date | number;
    /**
     * The timestamp (if applicable) when the message was deleted. 
     * The timestamp is in RFC3339 format: yyyy-MM-ddTHH:mm:ssZ.
     */
    deletedOn?: string;
    /**
     * The last timestamp (if applicable) when the message was edited. 
     * The timestamp is in RFC3339 format: yyyy-MM-ddTHH:mm:ssZ.
     */
    editedOn?: string;
    content: CommentContent;
    className?: string;
};

export type CommentMessage = {
    id: string;
    senderDisplayname: string;
    senderAvatarUrl?: string;
    sequenceId: number | string;
    createdOn: string | Date | number;
    deletedOn?: string;
    editedOn?: string;
    content: CommentContent;
}

const Comment = (props: CommentProps) => {
    const { senderDisplayName, senderAvatarUrl } = props;
    const { createdOn, deletedOn, editedOn } = props;
    const { content } = props;
    const { className } = props;

    let dateHistory: string;
    if (deletedOn) {
        dateHistory = `deleted ${moment(deletedOn).fromNow()}`;
    } else if (editedOn) {
        dateHistory = `edited ${moment(editedOn).fromNow()}`;
    } else {
        dateHistory = moment(createdOn).fromNow();
    }

    return (
        <div className={['comment', className].join(' ')}>
            <Avatar name={senderDisplayName} src={senderAvatarUrl ? senderAvatarUrl : undefined} />
            <div>
                <p className={['comment-author'].join(' ')}>
                    {senderDisplayName}
                </p>
                <p className={['comment-date-history'].join(' ')}>
                    {dateHistory}
                </p>
                {!deletedOn && <p className={['comment-content'].join(' ')}>{content.message}</p>}
            </div>
        </div>
    )
}

export default Comment