// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * @author Alex Alvarez Gárciga
 * 
 */

import { protectedWebApi } from "../auth"
import { AuthZ } from "../library/security"
import RoleModel from "../models/RoleModel"
import { ResponseDTO } from "../types/dto"


export type RoleDTO = {
    roleId: string
    name: string
    description: string
    applicationRoleId: string
    applicationRoleName: string
    featurePermissionIds: string[]
}

const RoleService = {
    getRoles: async (searchPredicate?: string, accessToken?: string) => {
        const endpointUrl = protectedWebApi.b2c.apiRoles.incaRoles.endpoint;

        if (!accessToken) {
            throw new Error("Access token is required");
        }
        try {
            const result = await AuthZ.fetch<ResponseDTO<RoleDTO[]>>(accessToken,
                `${endpointUrl}/${searchPredicate}`);
            if (result instanceof Response) {
                return result
            }
            console.table(result.value);
            //throw new Error('Error Testing');
            return result.value.map((role) => new RoleModel(
                role.roleId,
                role.name,
                role.description,
                {
                    id: role.applicationRoleId,
                    name: role.applicationRoleName
                },
                role.featurePermissionIds || []
            ));
        } catch (error) {
            return Promise.reject(error);
        }
    }
}

export default RoleService;