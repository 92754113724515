// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * @author username
 * 
 */

import { useEffect, useRef, useState } from 'react';

import { AuthenticationResult } from '@azure/msal-browser';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useService from '../../../hooks/security/use-service';
import styles from './Search.module.scss';
import './styles.scss';

export interface ISearchProps<T extends unknown> {
    field: keyof T
    label?: string
    title: string
    placeholder: string
    scopes: string[]
    serviceEndpointHandler: (searchPredicate?: string, accessToken?: string) => Promise<T[] | Response>
    onFetch: (data: T[]) => void
    setError: (error: undefined) => void
    setLoading: (isLoading: boolean) => void
};

const Search = <T extends unknown>(props: ISearchProps<T>) => {

    const { field } = props;
    const { title, label, placeholder } = props;
    const { scopes, serviceEndpointHandler } = props;
    const { onFetch, setError, setLoading } = props;
    const elementId = `search-${field.toLocaleString()}`;

    const inputRef = useRef<HTMLInputElement>(null);
    const [enteredFilter, setEnteredFilter] = useState('');
    const { consumeService, isLoading, setIsLoading } = useService();

    useEffect(() => {

        const timer = setTimeout(() => {
            if (enteredFilter === inputRef?.current?.value) {
                const query = enteredFilter.length === 0
                    ? ''
                    : `?$filter=startsWith(${field.toLocaleString()}, '${enteredFilter}')`;
                consumeService(
                    scopes,
                    (response: AuthenticationResult) => response.accessToken
                        ? serviceEndpointHandler(query, response.accessToken)
                            .then(data => {
                                if (data instanceof Response) {
                                    console.error(data)
                                } else {

                                    setIsLoading(false);
                                    onFetch(data);
                                }
                            })
                            .catch(error => {
                                setError(error?.message);
                                setIsLoading(false);
                            })
                        : Promise.reject(new Error('Security Error whilts getting analytics: No access token'))
                )
            }

        }, 500);
        return () => {
            clearTimeout(timer);
        }
    }, [
        enteredFilter,
        // inputRef,
        // field,
        consumeService,
        // scopes,
        serviceEndpointHandler,
        // onFetch,
        // setError,
        // setIsLoading
    ]);

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading, setLoading]);

    return (
        <section className={['search', styles['search']].join(' ')}>
            <label htmlFor={elementId}>{label ? label : field.toLocaleString()}</label>
            <div>

                <input type="text" id={elementId} title={title} placeholder={placeholder}
                    ref={inputRef}
                    value={enteredFilter}
                    onChange={event => setEnteredFilter(event.target.value)}
                />
                {isLoading && <FontAwesomeIcon icon={faSpinner} />}
            </div>
        </section>
    );
}

export default Search;