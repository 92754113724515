// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * @author Alex Alvarez Gárciga
 * 
 */

import React, { DetailedHTMLProps, SelectHTMLAttributes } from 'react';

import { FieldHookConfig, useField } from 'formik';
import './styles.scss';
import styles from './Dropdown.module.scss';

interface DropdownProps {
    children?: React.ReactNode;
    label?: string;
    id?: string;
};

/**
 * 
 * @param param0 
 * @returns 
 * @example
 * 
 * <Dropdown label="Job Type" name="jobType">
 *   <option value="">Select a job type</option>
 *   <option value="designer">Designer</option>
 *   <option value="development">Developer</option>
 *   <option value="product">Product Manager</option>
 *   <option value="other">Other</option>
 * </Dropdown>
 * 
 */
// const Dropdown = ({ id, label, children, ...props }: DropdownProps & FieldInputProps<string>) => {
const Dropdown = ({ id, label, children, ...props }: DropdownProps
    & FieldHookConfig<string>
    & DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>) => {

    const [field, meta] = useField(props);
    return (
        <>

            <label htmlFor={id || props.name}>{label}</label>
            <select className={['dropdown', styles['dropdown']].join(' ')}
                {...field}
                {...props}
                id={id || props.name}
            >
                {children}
            </select>
            {meta.touched && meta.error ? (
                <div className={['dropdown-error', styles['dropdown-error']].join(' ')}>{meta.error}</div>
            ) : null}
        </>
    );
}

export default Dropdown;