import { AuthenticationResult } from '@azure/msal-browser';
import React, { useEffect, useState } from 'react'
import protectedWebApi from '../../../../auth/protected-web-api';
import useService from '../../../../hooks/security/use-service';
import MenuService from '../../../../services/MenuService';
import { IMenuItem } from '../../../../types/interfaces';
import VerticalMenuItem from '../VerticalMenuItem';

import styles from './VerticalMenu.module.css';

type VerticalMenuProps = {
    //children: React.ReactNode,
    menu?: IMenuItem[],
    isCollapsed?: boolean,
}

// const VerticalMenu = ({ children, menu: _menu }: VerticalMenuProps) => {
const VerticalMenu = ({ menu: _menu, isCollapsed = false }: VerticalMenuProps) => {

    const [menu, setMenu] = useState<IMenuItem[]>();
    const { consumeService } = useService();
    useEffect(() => {
        if (!_menu) {
            consumeService(
                protectedWebApi.b2c.apiMenu.menuItems.scopes,
                (response: AuthenticationResult) => {
                    return response.accessToken
                        ? MenuService.getMenuItems(response.accessToken)
                            .then(data => {
                                if (data instanceof Response) {
                                    console.error(data)
                                } else {

                                    setMenu(data);
                                }
                            })
                        : Promise.reject(new Error('Security Error whilts getting menu items: No access token'));
                }
            );
        } else {
            setMenu(_menu);
        }
        // }, [children]);
    }, [_menu, consumeService]);


    return (
        <ul className={["vertical-menu", `${styles['vertical-menu']} ${isCollapsed ? styles.collapsed : styles.open}`].join(" ")}>
            {
                // React.Children.map(children, (child, index) => {
                //     if (React.isValidElement(child)) {
                //         return React.cloneElement(child, { menu: menu ? menu[index] : null });
                //     }
                // })
                menu && menu.map((menuItem, index) => <VerticalMenuItem menu={menuItem} key={index} isCollapsed={isCollapsed} />)
            }
        </ul>
    )
}

export default VerticalMenu