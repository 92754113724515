// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * @author username
 * 
 */

import { Formik, FormikHelpers } from 'formik';
import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import UserModel from '../../../models/UserModel';
import { LoosedObjectProps } from '../../../types/global';
import { User } from '../../../types/interfaces';

import { AuthenticationResult } from '@azure/msal-browser';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import { protectedWebApi } from '../../../auth';
import { Strings } from '../../../constants';
import useService from '../../../hooks/security/use-service';
import RoleModel from '../../../models/RoleModel';
import UserService from '../../../services/UserService';
import { Button, Dialog } from '../../customs';
import FormGroup from '../../customs/FormGroup';
import RoleTable from '../RoleTable';
import styles from './UserFormWithRoles.module.scss';

interface UserFormWithRolesProps {
    user?: UserModel;
    className?: string;
};

const defaultValues: LoosedObjectProps<User> = {
    active: true,
    displayName: '',
    givenName: '',
    mail: '',
    surname: '',
    mobilePhone: '',
    preferredLanguage: 'en-Us',
    userPrincipalName: '',
}

const validationSchema = Yup.object().shape({
    displayName: Yup.string()
        .required('Required'),
    givenName: Yup.string()
        .required('Required'),
    mail: Yup.string()
        .email('Invalid email address')
        .required('Required'),
    surname: Yup.string()
        .required('Required'),
    mobilePhone: Yup.string()
        .matches(
            /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/,
            "Mobile phone must be a valid phone number"),
    preferredLanguage: Yup.string(),
})

const UserFormWithRoles = ({ user, ...props }: UserFormWithRolesProps) => {

    const { className } = props;
    const navigate = useNavigate();
    const { id } = useParams();
    const [userForEdit, setUserForEdit] = useState<UserModel>();
    const [isCreateMode, setIsCreateMode] = useState(!id);
    const [selectedRows, setSelectedRows] = useState<ReadonlySet<string>>(() => new Set());
    const [initialValues, setInitialValues] = useState<LoosedObjectProps<User>>(user || defaultValues);
    const {
        consumeService: consumeServiceUpdate,
        isLoading: isLoadingServiceUpdate,
        setIsLoading: setIsLoadingServiceUpdate,
        error: errorServiceUpdate } = useService<UserModel>();
    const [userAlreadyExistModalIsVisible, setUserAlreadyExistModalIsVisible] = useState(false);
    const [enteredMail, setEnteredMail] = useState(initialValues.mail);
    const mailInputRef = useRef<HTMLInputElement>(null);
    const [rows, setRows] = useState<RoleModel[]>([]);
    const {
        consumeService: consumeServiceSetActive,
        isLoading: isLoadingServiceSetActive,
        setIsLoading: setIsLoadingServiceSetActive,
        error: errorServiceSetActive
    } = useService<UserModel>();
    const [isNotForEdditing, setIsNotForEdditing] = useState(false);
    const {
        consumeService: consumeServiceGetByEmail,
        isLoading: isLoadingServiceGetByEmail,
        setIsLoading: setIsLodingGetByEmail,
        error: errorGetByEmail
    } = useService<UserModel>();


    const createUser = (userModel: UserModel) => {
        consumeServiceUpdate(
            protectedWebApi.b2c.apiUser.incaUsers.scopes,
            (response: AuthenticationResult) => response.accessToken
                ? UserService.create(userModel, response.accessToken)
                    .then(() => {
                        //todo: navigate to the user list screen (use redirect or explicit navigation)
                        setIsLoadingServiceUpdate(false)
                        navigate("/administration/users")
                    })
                    .catch((reason) => {
                        // Todo: implement retry mechanism and UI error display through modal toasts.
                        throw new Error(reason);

                    })
                : Promise.reject(new Error(Strings.errors.noAccessToken))
        )
    }

    const updateUser = (userModel: UserModel) => {

        consumeServiceUpdate(
            protectedWebApi.b2c.apiUser.incaUsers.scopes,
            (response: AuthenticationResult) => response.accessToken
                ? UserService.update(userModel, response.accessToken)
                    .then(() => {
                        //todo: navigate to the user list screen (use redirect or explicit navigation)
                        setIsLoadingServiceUpdate(false)
                        navigate("/administration/users")
                    })
                    .catch((reason) => {
                        // Todo: implement retry mechanism and UI error display through modal toasts.
                        throw new Error(reason);

                    })
                : Promise.reject(new Error(Strings.errors.noAccessToken))
        )
    }

    const onSubmitHandler = async (values: LoosedObjectProps<User>,
        { setSubmitting }: FormikHelpers<LoosedObjectProps<User>>) => {

        try {
            values.roles = Array.from(selectedRows);

            if (isCreateMode) {
                createUser(new UserModel(
                    null,
                    true,
                    values.mail!,
                    values.givenName!,
                    values.surname!,
                    values.displayName!,
                    values.userPrincipalName,
                    values.mobilePhone,
                    values.preferredLanguage,
                    values.roles
                ))
            } else {
                updateUser(
                    new UserModel(
                        id!,
                        values.active!,
                        values.mail!,
                        values.givenName!,
                        values.surname!,
                        values.displayName!,
                        values.userPrincipalName,
                        values.mobilePhone,
                        values.preferredLanguage,
                        values.roles
                    )
                )
            }

        } catch (error) {
            setSubmitting(false);
            throw error;
        }
    }

    const showUserAlreadyExistModal = () => {
        setUserAlreadyExistModalIsVisible(true);
    }

    const closeUserAlreadyExistModal = () => {
        setUserAlreadyExistModalIsVisible(false);
    }

    const okUserAlreadyExistModalButtonHandler: MouseEventHandler<HTMLButtonElement> = (event) => {

        navigate(`/administration/users/${userForEdit?.id}/edit`);
    }

    const setActive = (id: string, isActive: boolean) => {
        consumeServiceSetActive(
            protectedWebApi.b2c.apiUser.incaUsers.scopes,
            (response: AuthenticationResult) => response.accessToken
                ? UserService.setActive(id, isActive, response.accessToken)
                    .then(() => {
                        setIsLoadingServiceSetActive(false);
                        navigate("/administration/users");
                    })
                    .catch((reason) => {
                        // Todo: implement retry mechanism and UI error display through modal toasts.
                        throw new Error(reason);
                    })
                : Promise.reject(new Error(Strings.errors.noAccessToken))

        )
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (isCreateMode && !isNotForEdditing && mailInputRef?.current?.value && enteredMail === mailInputRef?.current?.value) {

                consumeServiceGetByEmail(
                    protectedWebApi.b2c.apiUser.incaUsers.scopes,
                    (response: AuthenticationResult) => response.accessToken
                        ? UserService.getUserByEmail(enteredMail, response.accessToken)
                            .then(data => {
                                if (data !== null && data instanceof UserModel) {
                                    setUserForEdit(data)
                                    showUserAlreadyExistModal()
                                }
                            })
                        : Promise.reject(new Error(Strings.errors.noAccessToken))
                )
            }
        }, 500);

        return () => {
            clearTimeout(timer)
        }
    }, [enteredMail, consumeServiceGetByEmail]);

    return (
        <div className={[
            styles['user-form-with-roles'],
            className
        ].join(' ')}>

            <Formik enableReinitialize={true} initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmitHandler}
            >
                {(formProps) => (
                    <>
                        <Dialog
                            isVisible={userAlreadyExistModalIsVisible}
                            closeModal={closeUserAlreadyExistModal}
                            title="User already exist"
                            footer={
                                <>
                                    <Button primary onClick={okUserAlreadyExistModalButtonHandler}>something ?"Load & Edit" : "Create"</Button>

                                    <Button onClick={() => {
                                        formProps.resetForm()
                                        setEnteredMail('')
                                        closeUserAlreadyExistModal()
                                    }}>Cancel</Button>
                                </>
                            }>
                            <p><br /></p>
                            <p>We found a user with the exact same email address ({userForEdit?.mail}) in our system.</p>
                            <ul>
                                <li>First name(s): {userForEdit?.givenName}</li>
                                <li>Last name: {userForEdit?.surname}</li>
                                <li>Mobile: {userForEdit?.mobilePhone}</li>
                            </ul>
                            <p>By pressing <strong>Load & Edit</strong> you will be able to update the existing user account. </p>
                            <p>However, if you choose to press <strong>Cancel</strong>, please re-enter the email address to create a new user account.</p>
                            <p><br /></p>
                        </Dialog>

                        <form onSubmit={formProps.handleSubmit}>
                            <FormGroup name='mail' className={styles['form-group']}>
                                <FormGroup.Label>eMail address*</FormGroup.Label>
                                {/* <FormGroup.Input type='email' /> */}
                                <input
                                    className={['form-group-input', formProps.errors.mail ? "error" : ""].join(" ")}
                                    id="mail"
                                    name='mail'
                                    type='email'
                                    value={enteredMail}
                                    ref={mailInputRef}
                                    title='mail'
                                    onBlur={formProps.handleBlur}
                                    onChange={(event) => {
                                        setEnteredMail(event.target.value)
                                        formProps.setFieldValue('mail', event.target.value)
                                    }}
                                />

                                <FormGroup.ErrorMessage />
                            </FormGroup>

                            <FormGroup name='givenName' className={styles['form-group']}>
                                <FormGroup.Label>First name(s)*</FormGroup.Label>
                                <FormGroup.Input />
                                <FormGroup.ErrorMessage />
                            </FormGroup>

                            <FormGroup name='surname' className={styles['form-group']}>
                                <FormGroup.Label>Last name*</FormGroup.Label>
                                <FormGroup.Input />
                                <FormGroup.ErrorMessage />
                            </FormGroup>

                            <FormGroup name='displayName' className={styles['form-group']}>
                                <FormGroup.Label>Display name*</FormGroup.Label>
                                <FormGroup.Input />
                                <FormGroup.ErrorMessage />
                            </FormGroup>

                            <FormGroup name='mobilePhone' className={styles['form-group']}>
                                <FormGroup.Label className=''>Mobile</FormGroup.Label>
                                <FormGroup.Input className='' />
                                <FormGroup.ErrorMessage className='' >
                                    {error => (<>{error.error}</>)}
                                </FormGroup.ErrorMessage>
                            </FormGroup>

                            <FormGroup name="preferredLanguage" className={styles['form-group']}>
                                <FormGroup.Label className=''>Language</FormGroup.Label>
                                <FormGroup.Dropdown className=''>
                                    <option value="en-Us">English</option>
                                    <option value="es-ES">Español</option>
                                    <option value="nl-NL">Nederlands</option>
                                </FormGroup.Dropdown>
                                <FormGroup.ErrorMessage className='' />
                            </FormGroup>

                            <div className={["form-section", styles["detail"]].join(' ')}>
                                <h4>Roles</h4>
                                <RoleTable
                                    rows={rows}
                                    setRows={setRows}
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                />
                                {/* Example of Render props pattern  */}
                                {/* {({ rows, selectedRows, showErrorDialog, sortColumns, errorMessage }) => (
                                    <>
                                        {selectedRows}
                                    </>
                                )}
                            </RoleTable> */}
                            </div>

                            <div className={["form-actions", styles["form-actions"]].join(" ")}>

                                <Button
                                    primary
                                    type='submit'
                                    disabled={formProps.isSubmitting || isLoadingServiceSetActive}
                                >{
                                        isCreateMode
                                            ? isLoadingServiceUpdate
                                                ? <> <FontAwesomeIcon className='animation-spinner' icon={faSpinner} /> Submit </>
                                                : "Submit"
                                            : isLoadingServiceUpdate
                                                ? <> <FontAwesomeIcon className='animation-spinner' icon={faSpinner} /> Update </>
                                                : "Update"}
                                </Button>

                                {formProps.values.id && <Button type='button'
                                    disabled={formProps.isSubmitting}
                                    onClick={() => {
                                        const active = !formProps.values.active;
                                        formProps.setFieldValue('active', active);
                                        setActive(formProps.values.id!, active);
                                    }}
                                >
                                    {formProps.values.active
                                        ? isLoadingServiceSetActive
                                            ? <> <FontAwesomeIcon className='animation-spinner' icon={faSpinner} /> Disable User </>
                                            : "Disable User"
                                        : isLoadingServiceSetActive
                                            ? <> <FontAwesomeIcon className='animation-spinner' icon={faSpinner} /> Enable User </>
                                            : "Enable User"}
                                </Button>}
                                {formProps.values.id && <Button type='button' disabled={formProps.isSubmitting} >
                                    Revoke application access
                                </Button>}
                                <Button type='button' onClick={() => {
                                    navigate("/administration/users")
                                }}>Cancel</Button>
                            </div>
                        </form>
                    </>
                )}

            </Formik>
        </div>
    );
}

export default UserFormWithRoles;