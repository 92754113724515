import React from 'react'
import { createPortal } from 'react-dom';

type Props = {
    title: string;
}

const appTitle = document.getElementById('app-title');

const AppTitle = ({ title }: Props) => {

    return appTitle && createPortal(
        title,
        appTitle
    );
}

export default AppTitle