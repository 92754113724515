import React from 'react'
import './styles.scss'
import styles from './Header.module.scss'

interface HeaderProps {
    logo: React.ReactNode;
    navigation: React.ReactNode;
    className: string;
}

const Header = ({ logo, navigation, className }: HeaderProps) => {
    return (
        <header className={['screen-header', styles.header, className].join(' ')}>
            <div className={['logo'].join(' ')}>{logo}</div>
            <div className={['navigation'].join(' ')}>{navigation}</div>
        </header>
    )
}

export default Header;