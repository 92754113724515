import { useMemo } from "react";
import { SortColumn } from "react-data-grid";
import { Comparator } from "../types/global";

const useReactDataGridSort = <T extends unknown>(
    comparator: (column: string) => Comparator<T>,
    rows: T[],
    sortColumns: readonly SortColumn[]) =>
    useMemo((): readonly T[] => {
        if (sortColumns.length === 0) return rows;
        return [...rows].sort((a, b) => {
            for (const column of sortColumns) {
                const columnComparator = comparator(column.columnKey)
                const result = columnComparator(a, b)
                if (result !== 0)
                    return column.direction === 'ASC' ? result : -result;
            }
            return 0;
        })
    }, [rows, sortColumns])

export default useReactDataGridSort;