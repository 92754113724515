// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * @author Alex Alvarez Gárciga
 * 
 */

import { protectedWebApi } from "../auth"
import { AuthZ } from "../library/security"
import PermissionModel from "../models/PermissionModel"
import { ResponseDTO } from "../types/dto"

export type PermissionDTO = {
    permissionId: string
    featureName: string
    featureId: string
    permissionKey: string
    description: string
}

const PermissionService = {
    getPermissions: async (searchPredicate?: string, accessToken?: string) => {
        const endpointUrl = protectedWebApi.b2c.apiPermissions.incaPermissions.endpoint;

        if (!accessToken) {
            throw new Error("Access token is required");
        }
        try {
            const result = await AuthZ.fetch<ResponseDTO<PermissionDTO[]>>(accessToken,
                `${endpointUrl}/${searchPredicate}`);
            if (result instanceof Response) {
                return result
            }
            console.table(result.value);
            //throw new Error('Error Testing');
            return result.value.map((permission) => new PermissionModel(
                permission.permissionId,
                permission.description,
                permission.permissionKey,
                permission.featureId,
                permission.featureName
            ));
        } catch (error) {
            return Promise.reject(error);
        }
    }
}

export default PermissionService;