import React from 'react'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

type Props = {
    height?: number;
    width?: number;
}
const defaultHeight = 160;
const defaultWidth = 400;

const ThreeDots = (props: Props & IContentLoaderProps) => {
    let height = props.height || defaultHeight;
    let width = props.width || defaultWidth;
    return (
        <ContentLoader
            viewBox={`0 0 ${width} ${height}`}
            height={height}
            width={width}
            backgroundColor="transparent"
            {...props}
        >
            <circle cx="150" cy="86" r="8" />
            <circle cx="194" cy="86" r="8" />
            <circle cx="238" cy="86" r="8" />
        </ContentLoader>
    )
}

export default ThreeDots