import UserForm from '../../../components/views/UserForm';
import styles from './AdministrationUserNewScreen.module.scss';

interface AdministrationUserNewScreenProps { }

const AdministrationUserNewScreen = ({ ...props }: AdministrationUserNewScreenProps) => {


    return (
        <>
            <header
                className={["screen-header", styles["screen-header"]].join(" ")}>
                <h3>New User</h3>
            </header>
            <UserForm
                className={['screen-content', styles['screen-content'], styles['new-user-form']].join(' ')} />
        </>
    )
}

export default AdministrationUserNewScreen