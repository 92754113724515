

import RoleForm from '../../../components/views/RoleForm';
import styles from './AdministrationRoleNewScreen.module.scss';

interface AdministrationRoleNewScreenProps { }

const AdministrationUserNewScreen = (props: AdministrationRoleNewScreenProps) => {
    return (
        <>
            <header className={styles["screen-header"]}>
                <h3>New Role</h3>
            </header>
            <RoleForm className={[styles['new-role-form'], styles['screen-content']].join(' ')} />
        </>
    )
}

export default AdministrationUserNewScreen