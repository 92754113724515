// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * @author Alex Alvarez Gárciga
 * 
 */

import React from 'react';

import './styles.scss';

interface SplitViewProps {
    /**
     * Deplare left and right pane. 
     * This component must have two children or none. 
     * Sometimes you need to set max-width: fit-content; 
     * so the content don't push the other pane's content away.
     * @param children
     */
    children?: React.ReactNode[];
    /**
     * 
     */
    leftChildClass?: string;
    /**
     * 
     */
    rightChildClass?: string;
    /**
     * 
     */
    leftWeight?: number;
    /**
     * 
     */
    rightWeight?: number;
    /**
     * @param left width value in pixels for a fixed width. 
     * Works using flow basis from flex.
     */
    leftWidth?: number | string;
    /**
     * @param right width value in pixels for a fixed width. 
     * Works using flow basis from flex.
     */
    rightWidth?: number;
    /**
     * 
     */
    leftMinWidth?: number | string;
    /**
     * 
     */
    rightMinWidth?: number | string;
    /**
     * 
     */
    leftMaxWidth?: string | number;
    /**
     * 
     */
    rightMaxWidth?: string | number;
    /**
     * @param debug is set to true the component will show an outline.
     */
    debug?: boolean;
};

const SplitView = ({
    children,
    leftChildClass,
    rightChildClass,
    leftWidth,
    rightWidth,
    leftWeight = 1,
    rightWeight = 1,
    leftMinWidth = 0,
    rightMinWidth = 0,
    leftMaxWidth = '100%',
    rightMaxWidth = '100%',
    debug = false,
}: SplitViewProps) => {

    let left, right;

    if (children && children.length && children[0]) {
        [left] = children;
    }
    if (children && children.length && children[1]) {
        [left, right] = children;
    }
    const debugClass = debug ? 'debug' : '';
    return (
        <div className={['split-view', debugClass].join(' ')}>
            <div
                className={['split-view-pane', 'split-view-pane-left', leftChildClass].join(' ')}
                style={{
                    flexGrow: leftWidth ? 0 : leftWeight,
                    flexBasis: leftWidth,
                    minWidth: leftMinWidth,
                    maxWidth: leftMaxWidth
                }}
            >
                {left}
            </div>
            <div
                className={['split-view-pane', 'split-view-pane-right', rightChildClass].join(' ')}
                style={{
                    flexGrow: rightWidth ? 0 : rightWeight,
                    flexBasis: rightWidth,
                    minWidth: rightMinWidth,
                    maxWidth: rightMaxWidth
                }}
            >
                {right}
            </div>
        </div >
    );
}

export default SplitView;