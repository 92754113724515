import { useCallback, useReducer } from "react";

enum FetchStateActionType {
    request = 0,
    response,
    error,
    clear
}

type FetchState = {
    loading: boolean;
    error?: string | undefined;
}

type FetchStateAction = {
    type: FetchStateActionType.request | FetchStateActionType.response | FetchStateActionType.clear,
} | {
    type: FetchStateActionType.error,
    payload?: string
}

const fetchReducer = (state: FetchState, action: FetchStateAction) => {
    switch (action.type) {
        case FetchStateActionType.request:
            return { loading: true, error: undefined }
        case FetchStateActionType.response:
            return { ...state, loading: false }
        case FetchStateActionType.error:
            return { loading: false, error: action.payload }
        case FetchStateActionType.clear:
            return { loading: false, error: undefined }
        default:
            throw new Error("Action type not declared how to be handled.");
    }
}

const useFetchState = () => {

    const [state, dispatch] = useReducer(fetchReducer, {
        loading: false,
        error: undefined
    } as FetchState)

    const setError = useCallback((error?: string) => {
        dispatch({
            type: FetchStateActionType.error,
            payload: error
        })
    }, [])

    const setLoading = useCallback((isLoading: boolean) => {
        if (isLoading) {
            dispatch({
                type: FetchStateActionType.request
            })
        } else {
            dispatch({
                type: FetchStateActionType.response
            })
        }
    }, [])

    const setClear = useCallback(() => {
        dispatch({
            type: FetchStateActionType.clear
        })
    }, [])

    return { state, setLoading, setError, setClear }
}

export default useFetchState

