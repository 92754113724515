// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * @author username
 * 
 */

import React from 'react';
import { useRouteError } from 'react-router-dom';

import styles from './Error.module.scss';

interface ErrorProps {

};

const Error = (props: ErrorProps) => {
    const error = useRouteError() as Response;
    console.log(error);
    return (
        <div className={[
            styles['error']
        ].join(' ')}>
            <h1>Error.</h1>
            {error && <p>{error.status} {error.statusText} </p>}
        </div>
    );
}

export default Error;