import { ChatClient, ChatMessage, ChatParticipant, ChatThreadClient, SendMessageOptions, SendMessageRequest } from '@azure/communication-chat';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { AuthenticationResult } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { faCircleExclamation, faMessageQuote } from '@fortawesome/pro-regular-svg-icons';
import { faCompress, faExpand } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { MouseEventHandler, useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { protectedWebApi } from '../../auth';
import { acsConfig } from '../../auth/acs-config';
import { CommentMessage } from '../../components/customs/collaboration/Comment';
import CommentBox, { CommentThreadCollection, CommnetThreadObject, ReplayingThreadHandler } from '../../components/customs/collaboration/CommentBox';
import AppTitle from '../../components/layouts/AppTitle';
import useService from '../../hooks/security/use-service';
import CommunicationService from '../../services/CommunicationService';
import InsightsService from '../../services/InsightsService';
import { useExpandedHandler } from '../AnalyticsScreen';
import styles from './AnalyticsViewScreen.module.scss';

type Props = {}

type EmbedToken = {
    token: string;
    tokenId: string;
    expiration: string;
}

type PowerBiConfigDTO = {
    featureId: string;
    name: string;
    description?: string;
    isPBI: boolean;
    pbIreportId: string;
    reportName: string;
    type: string;
    embedUrl: string;
    embedToken: EmbedToken;
}

enum CommentsStateActionType {
    addThread,
    loadThread,
    addComment,
    reset
}

type CommentsStateActionAddCommentPayload = {
    commentMessage: CommentMessage;
    threadId: string;
}

type CommentsStateAction = {
    type: CommentsStateActionType.addThread;
    payload: Required<CommnetThreadObject>;
} | {
    type: CommentsStateActionType.loadThread;
    payload: Required<CommnetThreadObject>;
} | {
    type: CommentsStateActionType.addComment;
    payload: CommentsStateActionAddCommentPayload;
} | {
    type: CommentsStateActionType.reset;
}

const commentsStateInit = (value: CommnetThreadObject[]): CommentThreadCollection => {
    return {
        nextLink: '',
        value: value
    }
}

const commentsStateReducer = (state: CommentThreadCollection, action: CommentsStateAction): CommentThreadCollection => {

    switch (action.type) {
        case CommentsStateActionType.addThread:
            return {
                ...state,
                value: [action.payload, ...state.value]
            };
        case CommentsStateActionType.loadThread:
            return {
                ...state,
                value: [...state.value, action.payload]
            };
        case CommentsStateActionType.addComment:
            return {
                ...state,
                value: state.value.map<CommnetThreadObject>(thread => {
                    if (thread.id === action.payload.threadId) {
                        return {
                            ...thread,
                            comments: {
                                ...thread.comments,
                                value: [...thread.comments.value, action.payload.commentMessage]
                            }
                        }
                    }
                    return thread;
                })
            };
        case CommentsStateActionType.reset:
            return commentsStateInit([]);
        default:
            throw new Error("Action type not declared how to be handled.");
    }
}

type CommunicationIdentity = {
    userId: string;
    accessToken: string;
}

const AnalyticsViewScreen = ({ }: Props) => {

    const { instance, accounts } = useMsal();
    const username = accounts && accounts[0] && accounts[0].username;
    const userDisplayName = accounts && accounts[0] && accounts[0].name || "Current User";

    const { expandedHandler } = useExpandedHandler();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isDashboardAvailable, setIsDashboardAvailable] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [embedConfig, setEmbedConfig] = useState<PowerBiConfigDTO>();
    const [postValue, setPostValue] = useState<string>('');
    const [chatThreadClients, setChatThreadClients] = useState<ChatThreadClient[]>([]);
    const [communicationUserIdentityState, setCommunicationUserIdentityState] = useState<CommunicationIdentity>({
        userId: '',
        accessToken: ''
    });
    const [communicationDashboardIdentityState, setCommunicationDashboardIdentityState] = useState<CommunicationIdentity>({
        userId: '',
        accessToken: ''
    });

    const initialComments: CommentThreadCollection = {
        nextLink: '',
        value: []
    };
    const [comments, commentsStateDispatch] = useReducer(commentsStateReducer, initialComments);

    const [userChatClient, setUserChatClient] = useState<ChatClient>();
    const [analyticChatClient, setAnalyticChatClient] = useState<ChatClient>();

    const { consumeService: consumeInsightsService, isLoading } = useService();
    const { consumeService: consumeCommunicationService } = useService();

    let params = useParams();

    // User & Dashboard Communication Identities
    useEffect(() => {
        consumeCommunicationService(
            protectedWebApi.b2c.apiCommunication.comunicationUsers.scopes,
            (response: AuthenticationResult) => {
                return response.accessToken
                    ? CommunicationService.getCommunicationIdentity(response.accessToken)
                        .then(data => {
                            if (data instanceof Response) {
                                console.error(data)
                            } else {

                                setCommunicationUserIdentityState({
                                    userId: data.userId,
                                    accessToken: data.accessToken.token
                                });
                            }
                        })
                        .catch(error => console.log(error))
                    : Promise.reject(new Error('Security Error whilts getting communication identity: No access token'));
            }
        );
        if (params.analyticsId) {
            consumeCommunicationService(
                protectedWebApi.b2c.apiCommunication.communicationDashboard.scopes,
                (response: AuthenticationResult) => {
                    return response.accessToken
                        ? CommunicationService.getCommunicationDashboardIdentity(params.analyticsId, response.accessToken)
                            .then(data => {
                                setCommunicationDashboardIdentityState({
                                    userId: data.communicationUserId,
                                    accessToken: data.accessToken.token
                                });
                            })
                            .catch(error => console.log(error))
                        : Promise.reject(new Error('Security Error whilts getting communication identity: No access token'));
                }
            );
        }
    }, [consumeCommunicationService, params]);

    // Dashboard configurations
    useEffect(() => {
        if (params.analyticsId) {
            consumeInsightsService(
                protectedWebApi.b2c.apiPowerBi.embedConfig.scopes,
                (response: AuthenticationResult) => {
                    return response.accessToken
                        ? InsightsService.getEmbedConfig(params.analyticsId, response.accessToken)
                            .then(data => {
                                setIsDashboardAvailable(true);
                                setEmbedConfig(data as PowerBiConfigDTO);
                            }).catch(error => {
                                setIsDashboardAvailable(false);
                            })
                        : Promise.reject(new Error('Security Error whilts getting analytics: No access token'));
                }
            );
        }
    }, [params, consumeInsightsService]);

    const createThread = async (chatClient: ChatClient, topic: string, participants: ChatParticipant[]) => {
        const threadRequest = {
            topic: topic
        };
        const threadOptions = {
            participants: participants
        }

        const threadResult = await chatClient?.createChatThread(
            threadRequest,
            threadOptions
        );
        const threadId = threadResult?.chatThread?.id;
        return threadId;
    }

    const sendMessage = async (userName: string, chatThreadClient: ChatThreadClient, message: string) => {
        const sendMessageRequest: SendMessageRequest = {
            content: message
        };
        const sendMessageOptions: SendMessageOptions = {
            senderDisplayName: userName,
            type: 'text',
            metadata: {
                hasAttachment: 'false'
            }
        };
        const sendMessageResult = await chatThreadClient.sendMessage(sendMessageRequest, sendMessageOptions);
        const messageId = sendMessageResult?.id;
        return messageId;
    }

    const getCommentMessage = (chatMessage: ChatMessage, avatarUrl?: string): CommentMessage => ({
        id: chatMessage.id,
        createdOn: chatMessage.createdOn,
        sequenceId: chatMessage.sequenceId,
        senderAvatarUrl: avatarUrl,
        senderDisplayname: chatMessage.senderDisplayName || '',
        content: {
            message: chatMessage.content?.message || ''
        }
    });

    const stateLoadComment = (threadId: string, chatMessage: ChatMessage) => {
        if (chatMessage.type === 'text' || chatMessage.type === 'html') {
            commentsStateDispatch({
                type: CommentsStateActionType.addComment, payload: {
                    threadId: threadId,
                    commentMessage: getCommentMessage(chatMessage)
                }
            });
        }
    }

    const stateFetchComments = async (chatClient: ChatClient, threadId: string) => {
        const threadClient = chatClient.getChatThreadClient(threadId);
        const messages = await threadClient.listMessages();
        for await (const message of messages) {
            stateLoadComment(threadId, message);
        }
    }

    const stateAddComment = async (chatClient: ChatClient, threadId: string, messageId?: string) => {
        const threadClient = chatClient.getChatThreadClient(threadId);
        const messages = await threadClient.listMessages();
        if (messageId) {
            for await (const message of messages) {
                if (message.id === messageId) {
                    stateLoadComment(threadId, message);
                    break;
                }
            }
        }
    }

    const stateAddThread = (threadId: string, action: CommentsStateActionType.addThread | CommentsStateActionType.loadThread = CommentsStateActionType.loadThread) => {
        commentsStateDispatch({
            type: action, payload: {
                id: threadId,
                comments: {
                    nextLink: '',
                    value: []
                }
            }
        });
    }

    const getDefaultParticipants = (participants: ChatParticipant[]): ChatParticipant[] => {
        return [{
            id: { communicationUserId: communicationUserIdentityState.userId },
            displayName: userDisplayName
        } as ChatParticipant, {
            id: { communicationUserId: communicationDashboardIdentityState.userId },
            displayName: embedConfig?.reportName
        } as ChatParticipant].concat(participants);
    }

    const onPostThreadHandler: MouseEventHandler<HTMLButtonElement> = (event) => {
        const participats = getDefaultParticipants([]);

        if (userChatClient && postValue) {
            createThread(userChatClient, `${embedConfig?.reportName}_by_${userDisplayName}`, participats)
                .then(async threadId => {
                    if (threadId) {
                        const chatThreadClient = userChatClient.getChatThreadClient(threadId);
                        chatThreadClients.push(chatThreadClient);
                        sendMessage(userDisplayName, chatThreadClient, postValue)
                            .then(async messageId => {
                                stateAddThread(threadId, CommentsStateActionType.addThread);
                                stateAddComment(userChatClient, threadId, messageId);
                            }).catch(error => {
                                throw new Error(`Error whilst sending comment: ${postValue} on thread ${threadId}`)
                            });
                    }
                });
        }
        setPostValue('');
    }

    const onReplayingThreadHandler: MouseEventHandler = (event) => {

    }

    const onReplyThreadHandler: ReplayingThreadHandler = (threadId, comment, event) => {
        if (userChatClient && comment)
            try {
                const chatThreadClient = chatThreadClients.find(thread => thread.threadId === threadId);
                if (chatThreadClient) {
                    sendMessage(userDisplayName, chatThreadClient, comment)
                        .then(async messageId => {
                            stateAddComment(userChatClient, threadId, messageId);
                        });
                }
            } catch (error) {

            }
    }

    // User Chat Client
    useEffect(() => {
        if (communicationUserIdentityState?.accessToken) {

            (async () => {
                try {
                    const chatClient = new ChatClient(acsConfig.endpoint
                        , new AzureCommunicationTokenCredential(communicationUserIdentityState.accessToken));
                    setUserChatClient(chatClient);
                    // Alex: Logic related to the user chat client
                } catch (error) {
                    console.log(error);
                }
            })();
        }
    }, [communicationUserIdentityState]);

    // Dashboard Chat Client
    useEffect(() => {
        if (communicationDashboardIdentityState?.accessToken) {
            (async () => {
                try {
                    const chatClient = new ChatClient(acsConfig.endpoint,
                        new AzureCommunicationTokenCredential(communicationDashboardIdentityState.accessToken));
                    setAnalyticChatClient(chatClient);
                } catch (error) {
                    console.log(error);
                }
            })();
        }
    }, [communicationDashboardIdentityState]);

    useEffect(() => {
        if (analyticChatClient) {
            setChatThreadClients([]);
            commentsStateDispatch({
                type: CommentsStateActionType.reset
            });

            (async () => {
                const threads = analyticChatClient.listChatThreads();
                for await (const thread of threads) {

                    //Alex: store all thread clients for future needs.
                    let threadClient = analyticChatClient.getChatThreadClient(thread.id);
                    setChatThreadClients(prev => {
                        const result = [...prev, threadClient];
                        return result;
                    });

                    stateAddThread(thread.id);
                    stateFetchComments(analyticChatClient, thread.id);
                }
            })();
        }

    }, [analyticChatClient]);

    return (
        <>
            <AppTitle title={`Curaçao Health & Wellness Platform | ${embedConfig?.reportName}`} />
            <header className={["insights-view", styles.header].join(" ")}>
                <button title='Comments'
                    onClick={() => {
                        setIsSidebarOpen(prev => !prev);
                    }}
                >
                    <FontAwesomeIcon icon={faMessageQuote} className={styles['header-icon']} />
                </button>
                <button title={isExpanded ? 'Compress' : 'Expand'}
                    onClick={() => {
                        setIsExpanded(prev => !prev);
                        expandedHandler()
                    }}
                >
                    <FontAwesomeIcon icon={!isExpanded ? faExpand : faCompress} className={styles['header-icon']} />
                </button>
            </header>
            {isDashboardAvailable
                ? <div className={[styles['analytics-container']].join(' ')}>
                    <PowerBIEmbed
                        embedConfig={{
                            // type: embedConfig?.type,   // Supported types: report, dashboard, tile, visual and qna
                            type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                            id: embedConfig?.pbIreportId,
                            embedUrl: embedConfig?.embedUrl,
                            accessToken: embedConfig?.embedToken.token,
                            tokenType: models.TokenType.Embed,
                            settings: {
                                // filterPaneEnabled: false,
                                // navContentPaneEnabled: false,

                                panes: {
                                    bookmarks: {
                                        visible: false
                                    },
                                    fields: {
                                        visible: false,
                                        expanded: false
                                    },
                                    filters: {
                                        expanded: true,
                                        visible: false
                                    },
                                    pageNavigation: {
                                        visible: true
                                    },
                                    selection: {
                                        visible: false
                                    },
                                    syncSlicers: {
                                        visible: false
                                    },
                                    visualizations: {
                                        expanded: true,
                                        visible: false
                                    }
                                },
                                // background: models.BackgroundType.Default,
                                background: models.BackgroundType.Transparent,
                            }
                        }}
                        cssClassName={[
                            styles['analytics'],
                            "report-style-class"
                        ].join(' ')
                        }
                    />
                    <aside className={[styles['sidebar'], isSidebarOpen ? styles['open'] : ''].join(' ')}>
                        <CommentBox
                            subject={embedConfig?.reportName || 'No dashboard selected'}
                            placeHolder={'Enter your comments here, and @mention people to grab their attention.'}
                            onPost={onPostThreadHandler}
                            onReplying={onReplayingThreadHandler}
                            onReply={onReplyThreadHandler}
                            value={postValue}
                            onChange={(event) => {
                                setPostValue(event.target.value);
                            }}
                            commentThreads={comments
                                // {
                                //     nextLink: '',
                                //     value: [{
                                //         id: '1',
                                //         comments: {
                                //             nextLink: '',
                                //             value: [{
                                //                 id: '1593107077690',
                                //                 senderDisplayname: 'John Tjon',
                                //                 senderAvatarUrl: 'https://i.pravatar.cc/64?img=18',
                                //                 createdOn: '2022-07-07T13:44:37.6830000Z',
                                //                 sequenceId: 1,
                                //                 content: {
                                //                     message: 'It\'s this data accurate?'
                                //                 }
                                //             }]
                                //         }
                                //     },
                                //     {
                                //         id: '2',
                                //         comments: {
                                //             nextLink: '',
                                //             value: [{
                                //                 id: '1593107077690',
                                //                 senderDisplayname: 'Dr. Alejandro Herrera',
                                //                 senderAvatarUrl: 'https://i.pravatar.cc/64?img=65',
                                //                 createdOn: '2022-07-07T13:44:37.6830000Z',
                                //                 sequenceId: 1,
                                //                 content: {
                                //                     message: 'What is the relevance of this data for our people?'
                                //                 }
                                //             },
                                //             {
                                //                 id: '1593107077691',
                                //                 senderDisplayname: 'Dr. Krishna Moniz',
                                //                 senderAvatarUrl: 'https://i.pravatar.cc/64?img=59',
                                //                 createdOn: '2022-07-07T18:44:37.6830000Z',
                                //                 sequenceId: 1,
                                //                 content: {
                                //                     message: 'This dashboard reflect what is the status of our people and how we can make a better use of the resource distribution.'
                                //                 }
                                //             }]
                                //         }
                                //     }]
                                // }
                            }
                        />
                    </aside>
                </div>
                : isLoading
                    ? <p className={styles.error}>
                        <FontAwesomeIcon icon={faCircleExclamation} />
                        <span>Loading...</span></p>
                    : <p className={styles.error}>
                        <FontAwesomeIcon icon={faCircleExclamation} />
                        <span>Dashboard is not available yet.</span></p>
            }
        </>
    )
}

export default AnalyticsViewScreen