import { MouseEventHandler, useState } from 'react';
import Button from '../../components/customs/Button';
import Dialog from '../../components/customs/Dialog';
import { AppTitle, DefaultLayout } from '../../components/layouts';
import { Strings } from '../../constants';

interface ModalExampleScreenProps {

}

const ModalExampleScreen = ({ }: ModalExampleScreenProps) => {

    const [modalIsVisible, setModalIsVisible] = useState(false);

    const title = "Modal Example";

    const showModal = () => {
        setModalIsVisible(true);
    }

    const closeModal = () => {
        setModalIsVisible(false);
    }

    const okModalButtonHandler: MouseEventHandler<HTMLButtonElement> = (event) => {
        console.log(`${event.target} was clicked!`);
    }
    const cancelModalButtonHandler: MouseEventHandler<HTMLButtonElement> = (event) => {
        console.log(`${event.target} was clicked!`);
        closeModal();
    }
    return (
        <DefaultLayout>
            <AppTitle title={`${title} | ${Strings.branding.logo} `} />

            <Dialog isVisible={modalIsVisible} closeModal={closeModal} title={title}
                footer={
                    <>
                        <Button primary onClick={okModalButtonHandler}>Ok</Button>
                        <Button onClick={cancelModalButtonHandler}>Cancel</Button>
                    </>
                }>

                <p>This is a Dialog Modal React Pattern example.</p>
            </Dialog>

            <div>
                <Button primary size='large' onClick={showModal}>Show Modal</Button>
                {modalIsVisible && (
                    <p>Showing Modal</p>
                )}
            </div>

        </DefaultLayout>)
}

export default ModalExampleScreen;