// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * @author username
 * 
 */

import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import SplitView from '../SplitView';

import './styles.scss';

interface AsideViewProps {
    children?: React.ReactElement[];
    width?: number;
    collapsedWidth?: number;
    className?: string;
    contentClassName?: string;
    title?: string;
};

const DEFAULT_ASIDE_WIDTH = 300;
const DEFAULT_ASIDE_WIDTH_COLLAPSED = 60;

const AsideView = ({
    title,
    width,
    collapsedWidth,
    children,
    className,
    contentClassName
}: AsideViewProps) => {

    width = width || DEFAULT_ASIDE_WIDTH;
    collapsedWidth = collapsedWidth || DEFAULT_ASIDE_WIDTH_COLLAPSED;

    const [isCollapsed, setIsCollapsed] = useState(false);

    className = ['aside-view', isCollapsed ? 'collapsed' : '', className].join(' ');
    contentClassName = ['aside-view-content', isCollapsed ? 'aside-collapsed' : '', contentClassName].join(' ');

    const firstChildCollapsable = useMemo(() => React.Children.map(children, (child, index) => {
        return index === 0 && child ? React.cloneElement(child, {
            isCollapsed
        }) : child;
    })?.[0], [children, isCollapsed]);

    return (
        <SplitView
            leftWidth={isCollapsed ? collapsedWidth : width}
            leftChildClass={className}
            rightChildClass={contentClassName}
        >
            <>
                <header>
                    <h3>
                        <button
                            title="Collapse"
                            onClick={() => { setIsCollapsed(value => !value) }}>
                            <FontAwesomeIcon icon={faBars} />
                        </button>
                        {!isCollapsed && title}
                    </h3>
                </header>
                {firstChildCollapsable}
            </>
            <>{children?.[1]}</>
        </SplitView>
    );
}

export default AsideView;