// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * @author Alex Alvarez Gárciga
 * 
 */

import React, { useEffect } from 'react';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { LoosedObjectProps } from '../../../types/global';
import { IRole } from '../../../types/interfaces';

import styles from './RoleForm.module.scss';
import { Button, Dropdown, TextBox } from '../../customs';
import PermissionTable from '../PermissionTable';

interface RoleFormProps {
    className?: string;
};

const initialValues: LoosedObjectProps<IRole> | { applicationRoleId?: string } = {
    name: '',
    description: '',
    permissionIds: [],
    applicationRoleId: ''
}

const RoleForm = (props: RoleFormProps) => {
    const { className } = props;

    return (
        <div className={[styles['role-form'], className].join(' ')}>
            <Formik initialValues={initialValues}
                validationSchema={Yup.object({
                    name: Yup.string().required('Required'),
                    description: Yup.string(),
                    applicationRoleId: Yup.string().required('Required')
                })}
                onSubmit={(values, { setSubmitting }) => {

                    setTimeout(() => {
                        console.log(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                    }, 500);
                }}
            >

                {(formProps) => (
                    <form onSubmit={formProps.handleSubmit}>
                        <div className={styles["form-group"]}>
                            <TextBox name="name" label="Name" />
                        </div>

                        <div className={styles["form-group"]}>
                            <TextBox name="description" label="Description" />
                        </div>

                        <div className={styles["form-group"]}>
                            <Dropdown name="applicationRoleId" label="Application Role">
                                <option value=''>Select option</option>
                                <option value="d432c439-5946-43f3-8b87-28e7908cde0c">Web.PopulationHealth</option>
                                <option value="26e12b2e-f9c3-4ab3-a44c-ecfe0e7e59e9">Web.CareCoordinator</option>
                                <option value="65f1e9c7-58f1-4e96-ad68-bda12d06dba5">Web.ApplicationManager</option>
                                <option value="c3cd4c51-5b18-4b59-9050-04c4b344ff60">Web.Patient</option>
                                <option value="ff61fab4-2a6f-4684-8f74-ea1153e05bfa">Web.Provider</option>
                                <option value="64f3f53a-9259-483c-b3ad-43c1edfd9a6e">eb.HealthInsuror</option>
                            </Dropdown>
                        </div>

                        <div className={[styles["form-group"], styles["detail"]].join(' ')}>
                            <PermissionTable />
                        </div>

                        <div className={styles["form-actions"]}>
                            <Button
                                primary
                                type='submit'
                                disabled={formProps.isSubmitting}
                            >Submit</Button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
}

export default RoleForm;
