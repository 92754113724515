import { AccessToken } from "../types/accessToken";
import { freeze, singleton } from "../types/decorators";
import { ICommunicationIdentity } from "../types/interfaces";

export default class CommunicationIdentityModel implements ICommunicationIdentity {
    private _userId: string;
    private _accessToken: AccessToken;

    public get userId(): string {
        return this._userId;
    }
    public set userId(value: string) {
        this._userId = value;
    }
    public get accessToken(): AccessToken {
        return this._accessToken;
    }
    public set accessToken(value: AccessToken) {
        this._accessToken = value;
    }

    /**
     *
     */
    constructor(userId: string, accessToken: AccessToken) {
        this._userId = userId;
        this._accessToken = accessToken;
    }

}