// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * @author Alex Alvarez Gárciga
 * 
 */

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/customs';
import { AppTitle } from '../../../components/layouts';
import RoleTable from '../../../components/views/RoleTable';
import { Strings } from '../../../constants';
import RoleModel from '../../../models/RoleModel';
import styles from './AdministrationRolesScreen.module.scss';

interface AdministrationRolesScreenProps { }

const AdministrationRolesScreen = (props: AdministrationRolesScreenProps) => {
    const title = 'Roles | Administration';
    const navigate = useNavigate();

    const [rows, setRows] = useState<RoleModel[]>([]);
    const [selectedRows, setSelectedRows] = useState<ReadonlySet<string>>(() => new Set());

    return (
        <>
            <AppTitle title={`${title} | ${Strings.branding.logo} `} />

            <header className={styles["screen-header"]}>
                <h3>Roles</h3>

                <Button primary onClick={() => {
                    navigate("new");
                }}>
                    New
                </Button>
            </header>

            <div className={styles["screen-content"]}>

                <RoleTable editable
                    rows={rows}
                    setRows={setRows}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    datagridClassName={styles['table']} />
            </div>
        </>
    );
}

export default AdministrationRolesScreen;