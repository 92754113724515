import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter, createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Routes } from 'react-router-dom';
import './App.css';
import AuthenticatedScreen from './components/containers/AuthenticatedScreen';
import Error from './components/views/Error';
import { AnalyticsScreen, AnalyticsViewScreen, InsightsScreen, Page404Screen } from './screens';
import AdministrationScreen, { AdministrationRoleNewScreen, AdministrationRolesScreen, AdministrationUserNewScreen, AdministrationUsersScreen } from './screens/Administration';
import UserEditScreen, { loader as userLoader } from './screens/Administration/UserEditScreen';
import ModalExampleScreen from './screens/ModalExampleScreen';
import UploadFileScreen from './screens/UploadFileScreen';


type AppProps = {
  msalInstance: PublicClientApplication;
}

type CreateRouter = {
  msalInstance: PublicClientApplication;
}

const createRouter = ({ msalInstance }: CreateRouter) => createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<Error />}>

      <Route index element={
        <AuthenticatedScreen>
          <InsightsScreen />
        </AuthenticatedScreen>
      } />

      <Route path='/analytics' element={
        <AuthenticatedScreen interactionType={InteractionType.Popup}>
          <AnalyticsScreen />
        </AuthenticatedScreen>
      } >
        <Route path=':analyticsId' element={<AnalyticsViewScreen />} />
      </Route>


      <Route path="/upload" element={
        <AuthenticatedScreen>
          <UploadFileScreen />
        </AuthenticatedScreen>
      } />


      <Route path="/modal-example" element={
        <AuthenticatedScreen>
          <ModalExampleScreen />
        </AuthenticatedScreen>
      } />

      <Route path="/administration" element={
        <AuthenticatedScreen>
          <AdministrationScreen />
        </AuthenticatedScreen>
      }>
        <Route path='users' element={<AdministrationUsersScreen />} />
        <Route path='users/new' element={<AdministrationUserNewScreen />} />
        <Route path='users/:id/edit'
          element={<UserEditScreen />}
          loader={userLoader(msalInstance)}
          errorElement={<Error />}
        />
        <Route path='roles' element={<AdministrationRolesScreen />} />
        <Route path='roles/new' element={<AdministrationRoleNewScreen />} />
      </Route>
      <Route path="*" element={<Page404Screen />} />

    </Route>

  ));

function App({ msalInstance }: AppProps) {

  return (
    <MsalProvider instance={msalInstance}>
      <RouterProvider router={createRouter({ msalInstance })} />
    </MsalProvider>
  );
}

export default App;
