// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * 
 * @author Alex Alvarez Gárciga
 * 
 */

import { protectedWebApi } from "../auth";
import { AuthZ } from "../library/security";
import CommunicationIdentityModel from "../models/CommunicationIdentityModel";
import { CommunicationIdentityDTO } from "../types/dto";

const CommunicationService = {
    getCommunicationIdentity: async (accessToken?: string): Promise<CommunicationIdentityModel | Response> => {
        const endpointUrl = protectedWebApi.b2c.apiCommunication.comunicationUsers.endpoint;

        if (!accessToken) {
            throw new Error("Access token is required");
        }
        try {
            const result = await AuthZ.fetch<CommunicationIdentityDTO[]>(accessToken, endpointUrl);
            if (result instanceof Response) {
                return result
            }
            return result.map((identity: CommunicationIdentityDTO) => new CommunicationIdentityModel(
                identity.communicationUserId,
                identity.accessToken
            ))[0];
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getCommunicationDashboardIdentity: async (id?: string, accessToken?: string) => {
        const endpointUrl = protectedWebApi.b2c.apiCommunication.communicationDashboard.endpoint;
        if (!accessToken) {
            throw new Error("Access token is required");
        }
        if (!id) {
            throw new Error("Report id is required");
        }
        try {
            const result = await AuthZ.fetch<any>(accessToken,
                `${endpointUrl}/${id}`);
            return result;
        } catch (error) {
            return Promise.reject(error);
        }
    }
}

export default CommunicationService;