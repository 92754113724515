
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/customs';
import FormGroup from '../../components/customs/FormGroup';
import { AppTitle, DefaultLayout } from '../../components/layouts';
import { Strings } from '../../constants';
import styles from './UploadFileScreen.module.scss';
import * as Yup from 'yup';

type UploadFileScreenProps = {}

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Required'),
    date: Yup.string()
        .required('Required')


})

const UploadFileScreen = ({ ...props }: UploadFileScreenProps) => {

    const onSubmitHandler = async (values: any) => {
        console.log(values);
    }

    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const initialValues = {
        name: '',
        date: today.toLocaleDateString(),
        file: []
    }

    const navigate = useNavigate();

    return (
        <DefaultLayout>
            <AppTitle title={`${Strings.branding.logo} | Upload File`} />

            <div className={[styles["upload-file"]].join(' ')}>
                <header className={["screen-header"].join(' ')}>
                    <h2>Upload File</h2>
                </header>

                <Formik initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitHandler}
                >

                    {formProps => (

                        <form className={[styles["upload-file-form"]].join(' ')} onSubmit={formProps.handleSubmit}>
                            <FormGroup name="name" className={styles['form-group']}>
                                <FormGroup.Label>Name*</FormGroup.Label>
                                <FormGroup.Input />
                                <FormGroup.ErrorMessage />
                            </FormGroup>

                            <FormGroup name="date" className={styles['form-group']}>
                                <FormGroup.Label>Date*</FormGroup.Label>
                                <FormGroup.Input type="date" />
                                <FormGroup.ErrorMessage />
                            </FormGroup>

                            <FormGroup name="file" className={styles['form-group']}>
                                <FormGroup.Label>File*</FormGroup.Label>
                                <FormGroup.Input type="file" />
                                <FormGroup.ErrorMessage />
                            </FormGroup>

                            <div className={["form-actions", styles["form-actions"]].join(" ")}>
                                <Button primary type="button" disabled={formProps.isSubmitting}>Upload</Button>
                                <Button type="button" disabled={formProps.isSubmitting}
                                    onClick={() => {
                                        navigate("/")
                                    }}
                                >Cancel</Button>

                            </div>

                        </form>
                    )}


                </Formik>

            </div>


        </DefaultLayout>
    )
}

export default UploadFileScreen;