import { AuthenticationResult, AuthError, EventMessage, EventType, InteractionType, RedirectRequest } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';
import { policies } from '../../../auth';
import { Colors } from '../../../constants';

interface AuthenticatedScreenProps {
    children: React.ReactNode;
    interactionType?: InteractionType;
}

const AuthenticatedScreen = ({ children, interactionType }: AuthenticatedScreenProps) => {
    /**
     * useMsal is hook that returns the PublicClientApplication instance, 
     * an array of all accounts currently signed in and an inProgress value 
     * that tells you what msal is currently doing. For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const { instance } = useMsal();

    /**
     * Using the event API, you can register an event callback that will do something when an event is emitted. 
     * When registering an event callback in a react component you will need to make sure you do 2 things.
     * 1) The callback is registered only once
     * 2) The callback is unregistered before the component unmounts.
     * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
     */
    useEffect(() => {
        const callbackId = instance.addEventCallback((event: EventMessage) => {

            // Alex: In case of forgot password handling needed uncomment this:
            if (event.eventType === EventType.LOGIN_FAILURE) {
                /**
                 * Handle the error code that Azure Active Directory B2C throws when trying to reset a password from the login page
                 * because password reset is not supported by a "sign-up or sign-in user flow".
                 */
                if (event.error instanceof AuthError) {
                    if (event.error && event.error.errorMessage.indexOf('AADB2C90118') > -1) {
                        if (event.interactionType === InteractionType.Redirect) {
                            instance.loginRedirect(policies.b2c.authorities.signIn as RedirectRequest);
                        } else if (event.interactionType === InteractionType.Popup) {
                            instance.loginPopup(policies.b2c.authorities.signIn as RedirectRequest);
                        }
                    }
                } else {
                    console.log(`Unknown error type: ${event.error}`);
                }
            }

            if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
                if (event?.payload) {
                    /**
                     * We need to reject id tokens that were not issued with the default sign-in policy.
                     * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
                     * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                     */
                    const trustedPolicy = ((event.payload as AuthenticationResult).idTokenClaims as any)['trp'] as string;
                    if (trustedPolicy === policies.b2c.names.signIn) {
                        console.log(Colors.console.yellow, 'Password has been reset successfully. \nPlease sign-in with your new password.');
                        return instance.logout();
                    } else if (trustedPolicy === policies.b2c.names.editProfile) {
                        console.log(Colors.console.yellow, 'Profile has been updated successfully. \nPlease sign-in again.');
                        return instance.logout();
                    }
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        }
    }, []);

    return (
        <MsalAuthenticationTemplate interactionType={interactionType || InteractionType.Redirect}>
            {children}
        </MsalAuthenticationTemplate>
    )
}

export default AuthenticatedScreen