import { IFeature } from "../types/interfaces";

export default class FeatureModel implements IFeature {
    private _id: string;
    private _name: string;
    private _description: string;
    private _type: number;
    private _typeAsString: string;

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }
    public get description(): string {
        return this._description;
    }
    public set description(value: string) {
        this._description = value;
    }
    public get type(): number {
        return this._type;
    }
    public set type(value: number) {
        this._type = value;
    }
    public get typeAsString(): string {
        return this._typeAsString;
    }
    public set typeAsString(value: string) {
        this._typeAsString = value;
    }

    constructor(id: string, name: string, description: string, type: number, typeAsString: string) {
        this._id = id;
        this._name = name;
        this._description = description;
        this._type = type;
        this._typeAsString = typeAsString;
    }

}