import { AccountInfo, AuthenticationResult, InteractionStatus, IPublicClientApplication } from "@azure/msal-browser";
import { Colors } from "../../constants";

const AuthN = {
    makeFetchRequest: async (
        msalInstance: IPublicClientApplication,
        account: AccountInfo,
        inProgress: InteractionStatus,
        scopes: string[],
        callback: (respose: AuthenticationResult) => Promise<any> | Promise<void>
    ) => {
        // in case of silent token acquisition fails, fallback to an interactive method
        try {
            if (account && inProgress === InteractionStatus.None) {
                msalInstance.acquireTokenSilent({
                    scopes: scopes,
                    account: account
                }).then((response: AuthenticationResult) => {
                    // TODO: Alex: validate response using MSAL instead of Claims
                    callback(response);
                }).catch(error => {
                    if (account && inProgress === InteractionStatus.None) {
                        msalInstance.acquireTokenPopup({
                            scopes: scopes
                        }).then((response: AuthenticationResult) => {
                            // TODO: Alex: validate response using MSAL instead of Claims
                            callback(response);
                        })
                    }
                })
            }
        } catch (error) {
            console.log(Colors.console.red, error, error);
            throw new Error("Request was not properly authenticated with error: " + error);
        }
    }
}

export default AuthN;