import { PublicClientApplication } from "@azure/msal-browser";

const getAccessTokenForScopes = async (msalInstance: PublicClientApplication, scopes: string[]) => {
    let activeAccount = msalInstance.getActiveAccount();

    if (activeAccount === null) {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            msalInstance.setActiveAccount(accounts[0]);
            activeAccount = accounts[0];
        }
    }

    const accessToken = await msalInstance.acquireTokenSilent({
        account: activeAccount!,
        scopes: scopes
    })
        .then(response => response.accessToken)
        .catch(error => {
            console.log("msal instance .getAccessTokenForScopes()")
            console.error(error);
        })

    return accessToken;
}

export default getAccessTokenForScopes;