import React, { MouseEventHandler, useState } from 'react'
import { Link } from 'react-router-dom';
import './styles.scss'

type AbstractNavigationEntry = {
    label: string;
    icon: React.ReactNode;
}

type ButtonNavigationEntry = AbstractNavigationEntry & {
    type: 'button';
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

type AnchorNavigationEntry = AbstractNavigationEntry & {
    type: 'anchor';
    link: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
}

type SpanNavigationEntry = AbstractNavigationEntry & {
    type: 'span';
    onClick?: MouseEventHandler<HTMLSpanElement>;
}

export type NavigationEntry = ButtonNavigationEntry | AnchorNavigationEntry | SpanNavigationEntry;

type NavigationDirection = 'horizontal' | 'vertical';

interface NavigationProps {
    entries: NavigationEntry[];
    direction: NavigationDirection;
    collapsedIcon: React.ReactNode;
};

const Navigation = ({ entries, collapsedIcon, direction = 'horizontal' }: NavigationProps) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const onClickHandler = () => {
        setIsExpanded(previousValue => !previousValue);
    }

    const expanded = isExpanded ? 'expanded' : '';
    return (
        <nav className={['navigation', direction].join(' ')}>

            {
                entries.length > 0 && <div onClick={onClickHandler} className={['hamburger'].join(' ')}>
                    {collapsedIcon}
                </div>
            }

            <div className={['navigation-entries', expanded].join(' ')}>
                {entries && entries.map((entry, index) => {
                    switch (entry.type) {
                        case 'anchor':
                            return (<Link to={entry.link} key={index} onClick={entry.onClick}>{entry.icon}<span>{entry.label}</span></Link>)
                        case 'button':
                            return (<button key={index} type="button" onClick={(event) => entry.onClick && entry.onClick(event)}>{entry.icon}<span>{entry.label}</span></button>)
                        case 'span':
                            return (<span key={index} onClick={(event) => entry.onClick && entry.onClick(event)}>{entry.icon} <span>{entry.label}</span></span>)
                    }
                })}
            </div>
        </nav>
    )
}

export default Navigation