import { faChartPie, faHome, faMessageMiddle, faRightFromBracket, faSliders } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const getFontAwesomeIcon = (name: string) => {
    switch (name) {
        case 'faHome':
            return <FontAwesomeIcon icon={faHome} />;
        case 'faChartPie':
            return <FontAwesomeIcon icon={faChartPie} />;
        case 'faRightFromBracket':
            return <FontAwesomeIcon icon={faRightFromBracket} />;
        case 'faMessageMiddle':
            return <FontAwesomeIcon icon={faMessageMiddle} />;
        case 'faSliders':
            return <FontAwesomeIcon icon={faSliders} />;
        default:
            break;
    }
}

export default getFontAwesomeIcon;