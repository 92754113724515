import { IPermission } from "../types/interfaces";

export default class PermissionModel implements IPermission {
    private _id: string;
    private _description: string;
    private _permissionKey: string;
    private _featureId: string;
    private _featureName: string;

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get description(): string {
        return this._description;
    }
    public set description(value: string) {
        this._description = value;
    }
    public get permissionKey(): string {
        return this._permissionKey;
    }
    public set permissionKey(value: string) {
        this._permissionKey = value;
    }
    public get featureId(): string {
        return this._featureId;
    }
    public set featureId(value: string) {
        this._featureId = value;
    }
    public get featureName(): string {
        return this._featureName;
    }
    public set featureName(value: string) {
        this._featureName = value;
    }

    /**
     *
     */
    constructor(
        id: string,
        description: string,
        permissionKey: string,
        featureId: string,
        featureName: string
    ) {
        this._id = id
        this._description = description
        this._permissionKey = permissionKey
        this._featureId = featureId
        this._featureName = featureName
    }

}