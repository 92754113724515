import { AuthenticationResult } from '@azure/msal-browser';
import React, { useEffect, useState } from 'react'
import useService from '../../../hooks/security/use-service';

type DatasetProps = {
    resourceName: string,
    children: React.ReactNode,
    scopes: string[],
    service: (accessToken: string) => Promise<any>
}

const Dataset = ({

    resourceName,
    children,
    scopes,
    service,
}: DatasetProps) => {

    const { consumeService } = useService();
    const [state, setState] = useState();

    useEffect(() => {
        consumeService(
            scopes,
            (response: AuthenticationResult) => {
                return response.accessToken
                    ? service(response.accessToken)
                        .then(data => {
                            setState(data);
                        })
                    : Promise.reject(
                        new Error(`Security Error whilts getting ${resourceName}: No access token`));
            }
        );
    }, [scopes, service, resourceName, consumeService]);

    return (
        <>
            {
                React.Children.map(children, child => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child, { [resourceName]: state });
                    }
                })
            }
        </>
    )
}

export default Dataset