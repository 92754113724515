export default {
    b2c: {
        names: {
            signUpSingInReset: 'B2C_1_susi_reset_v0',
            editProfile: 'B2C_1_edit_profile_v0',
            signIn: 'B2C_1_florence_si_v0',
        },
        authorities: {
            signUpSingInReset: {
                //authority: 'https://florencecw.b2clogin.com/florencecw.onmicrosoft.com/B2C_1_susi_reset_v0',
                authority: 'https://florencecw.b2clogin.com/florencecw.onmicrosoft.com/B2C_1_florence_susi_v0',
            },
            editProfile: {
                authority: 'https://florencecw.b2clogin.com/florencecw.onmicrosoft.com/B2C_1_edit_profile_v0',
            },
            signIn: {
                authority: 'https://florencecw.b2clogin.com/florencecw.onmicrosoft.com/B2C_1_florence_si_v0',
            }
        },
        domain: 'florencecw.b2clogin.com',
    }
}