import React from 'react';
import styles from './Backdrop.module.css';

type BackdropProps = {
    isVisible: boolean;
}

const Backdrop = ({ isVisible }: BackdropProps) => {
    const cssClasses = [styles.backdrop, isVisible ? styles.visible : styles.hidden].join(' ');
    return (
        <div className={cssClasses}></div>
    )
}

export default Backdrop