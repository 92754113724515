export default {
    b2c: {
        apiInsights: {
            apiReports: {
                endpoint: 'https://florencedashboards.azurewebsites.net/api/dashboards',
                scopes: ['https://florencecw.onmicrosoft.com/48c3b02c-e8a5-4092-9eeb-18d06ace3e9c/Insights.Read'],
            }
        },
        apiMenu: {
            menuItems: {
                endpoint: 'https://florencedashboards.azurewebsites.net/api/menu-items',
                scopes: ['https://florencecw.onmicrosoft.com/48c3b02c-e8a5-4092-9eeb-18d06ace3e9c/Insights.Read'],
            },
        },
        apiPowerBi: {
            embedConfig: {
                endpoint: 'https://florencedashboards.azurewebsites.net/api/dashboards',
                scopes: ['https://florencecw.onmicrosoft.com/48c3b02c-e8a5-4092-9eeb-18d06ace3e9c/Insights.Read'],
            }
        },
        apiCommunication: {
            comunicationUsers: {
                endpoint: 'https://florencedashboards.azurewebsites.net/api/b2c-communication-users/',
                scopes: ['https://florencecw.onmicrosoft.com/48c3b02c-e8a5-4092-9eeb-18d06ace3e9c/Insights.Read']
            },
            communicationDashboard: {
                endpoint: ' https://florencedashboards.azurewebsites.net/api/feature-communication-users/',
                scopes: ['https://florencecw.onmicrosoft.com/48c3b02c-e8a5-4092-9eeb-18d06ace3e9c/Insights.Read']
            }
        },
        apiUser: {
            users: {
                endpoint: 'https://florencedashboards.azurewebsites.net/api/users/',
                scopes: [
                    'https://florencecw.onmicrosoft.com/48c3b02c-e8a5-4092-9eeb-18d06ace3e9c/Users.Read',
                    'https://florencecw.onmicrosoft.com/48c3b02c-e8a5-4092-9eeb-18d06ace3e9c/Users.Write'
                ]
            },
            incaUsers: {
                endpoint: 'https://florencedashboards.azurewebsites.net/api/new-inca-users',
                scopes: [
                    'https://florencecw.onmicrosoft.com/48c3b02c-e8a5-4092-9eeb-18d06ace3e9c/Users.Read',
                    'https://florencecw.onmicrosoft.com/48c3b02c-e8a5-4092-9eeb-18d06ace3e9c/Users.Write'
                ]
            },
            incaUsersTest: {
                endpoint: 'https://florencedashboards.azurewebsites.net/api/inca-users',
                scopes: [
                    'https://florencecw.onmicrosoft.com/48c3b02c-e8a5-4092-9eeb-18d06ace3e9c/Users.Read',
                    'https://florencecw.onmicrosoft.com/48c3b02c-e8a5-4092-9eeb-18d06ace3e9c/Users.Write'
                ]
            }
        },
        apiRoles: {
            incaRoles: {
                endpoint: 'https://florencedashboards.azurewebsites.net/api/new-inca-roles',
                scopes: [
                    'https://florencecw.onmicrosoft.com/48c3b02c-e8a5-4092-9eeb-18d06ace3e9c/Roles.Read',
                    'https://florencecw.onmicrosoft.com/48c3b02c-e8a5-4092-9eeb-18d06ace3e9c/Roles.Write'
                ]
            }
        },
        apiPermissions: {
            incaPermissions: {
                endpoint: 'https://florencedashboards.azurewebsites.net/api/new-inca-permissions',
                scopes: [
                    'https://florencecw.onmicrosoft.com/48c3b02c-e8a5-4092-9eeb-18d06ace3e9c/Permissions.Read',
                    'https://florencecw.onmicrosoft.com/48c3b02c-e8a5-4092-9eeb-18d06ace3e9c/Permissions.Write'
                ]
            }
        }
    }
}