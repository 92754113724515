// Copyright 2022 Origin It Solutions B.V

/**
 * (Type docs)
 * @name RoleTable
 * @namespace components
 * @module views
 * @author Alex Alvarez Gárciga
 * 
 */

import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataGrid, { Column, SelectColumn, SortColumn } from 'react-data-grid';
import { protectedWebApi } from '../../../auth';
import { compareTwoNumbers, compareTwoStrings } from '../../../functions';
import { useFetchState } from '../../../hooks';
import useReactDataGridSort from '../../../hooks/useReactDataGridSort';
import RoleModel from '../../../models/RoleModel';
import RoleService from '../../../services/RoleService';
import { Comparator } from '../../../types/global';
import { Button, Dialog, Search } from '../../customs';

import styles from './RoleTable.module.scss';

interface RolesTableSummaryRow {
    id: string;
    totalCount: number;
}

const getRoleTableModelComparator = (sortColumn: string): Comparator<RoleModel> => {
    switch (sortColumn) {
        case 'name':
        case 'description':
            return (a, b) => compareTwoStrings(a[sortColumn], b[sortColumn])
        case 'permissionIds':
            return (a, b) => compareTwoNumbers(a[sortColumn].length, b[sortColumn].length)
        default:
            throw new Error(`Unsupported sort on column: "${sortColumn}"`)
    }
}

const getRolesTableColumnsConfig = (isEditable: boolean = false): readonly Column<RoleModel, RolesTableSummaryRow>[] => [
    SelectColumn,
    {
        key: "name",
        name: "Name",
        summaryFormatter: () => (<strong>Total</strong>)
    },
    {
        key: 'description',
        name: "Description",
        summaryFormatter: ({ row }) => (<>{`${row.totalCount} role(s)`}</>)
    },
    {
        key: 'permissionIds',
        name: 'Permissions',
        width: 110,
        formatter: ({ row }) => (<span className={styles['cell-permissions']}>{row.permissionIds.length}</span>)
    },
    ...isEditable ? [{
        key: 'actions',
        name: "",
        sortable: false,
        width: 50,
        formatter: ({ row }) => {
            return (<Button size='small' onClick={(event) => {
                console.log(`Clicking on ${row.id}`)
            }}
                title="Delete"
            ><FontAwesomeIcon icon={faTrash} /></Button>)
        }
    } as Column<any>] : []
]

interface RoleTableState {
    rows: RoleModel[]
    selectedRows: ReadonlySet<string>
    sortColumns: readonly SortColumn[]
    showErrorDialog: boolean
    errorMessage?: string
}

interface RoleTableProps {
    children?: (state: RoleTableState) => JSX.Element
    className?: string
    datagridClassName?: string
    editable?: boolean

    rows: RoleModel[]
    setRows: React.Dispatch<React.SetStateAction<RoleModel[]>>
    selectedRows: ReadonlySet<string>
    setSelectedRows: React.Dispatch<React.SetStateAction<ReadonlySet<string>>>

};

// Lifting state
const RoleTable = ({ editable = false, ...props }: RoleTableProps) => {
    const { className, datagridClassName } = props;
    const { rows, setRows } = props;
    const { selectedRows, setSelectedRows } = props;

    const {
        state: fetchState,
        setLoading: fetchSetLoading,
        setError: fetchSetError,
        setClear: fetchSetClear
    } = useFetchState();

    const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>(([]));
    const [showErrorDialog, setShowErrorDialog] = useState(false);

    const sortedRows = useReactDataGridSort<RoleModel>(getRoleTableModelComparator, rows, sortColumns);
    const columns: readonly Column<RoleModel, RolesTableSummaryRow>[] = useMemo(() =>
        getRolesTableColumnsConfig(editable), [editable]);

    const summaryRows = useMemo(() => {
        const summaryRow: RolesTableSummaryRow = {
            id: 'total_summary',
            totalCount: rows.length
        };
        return [summaryRow];
    }, [rows]);

    const filterHandler = useCallback((data: RoleModel[]) => {
        setRows(data);
    }, [setRows]);

    useEffect(() => {
        if (fetchState.error) {
            setShowErrorDialog(true);
        } else {
            setShowErrorDialog(false);
        }
    }, [fetchState]);

    const closeErrorDialogHanlder = () => {
        fetchSetClear()
    };

    return (
        <div className={[styles['role-table'], className].join(' ')}>

            <Dialog isVisible={showErrorDialog} closeModal={closeErrorDialogHanlder}
                title={'Error Dialog'}
                footer={
                    <>
                        <Button onClick={closeErrorDialogHanlder}>Close</Button>
                    </>
                }>

                <p>{fetchState?.error}</p>
            </Dialog>
            {props.children?.({
                rows,
                selectedRows,
                sortColumns,
                showErrorDialog,
                errorMessage: fetchState?.error
            })}

            <div className={styles["datagrid-header"]}>
                <Search field='name'
                    title='Search by name'
                    label='Search'
                    placeholder='name'
                    scopes={protectedWebApi.b2c.apiRoles.incaRoles.scopes}
                    serviceEndpointHandler={RoleService.getRoles}
                    onFetch={filterHandler}
                    setError={fetchSetError}
                    setLoading={fetchSetLoading}
                />
            </div>

            {
                rows.length
                    ? !fetchState.loading &&
                    <DataGrid className={[styles.datagrid, 'rdg-light', datagridClassName].join(' ')}
                        headerRowHeight={40}
                        rowHeight={40}
                        defaultColumnOptions={{
                            sortable: true,
                            resizable: true
                        }}
                        columns={columns}
                        rows={sortedRows}
                        summaryRows={summaryRows}
                        rowKeyGetter={(row) => row.id}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={setSelectedRows}
                        cellNavigationMode={'CHANGE_ROW'}
                        sortColumns={sortColumns}
                        onSortColumnsChange={setSortColumns}

                    />
                    : fetchState.loading ? <p>Retreiving records</p> : <p>No records</p>
            }

        </div>

    );
}

export default RoleTable;