const Global = {

}

const Formats = {
    datetime: "MM/DD/yyyy hh:mm a"
}

const Branding = {
    //logo: "SZV. Social & Health Insurance"
    logo: "Curaçao Health & Wellness Platform"
}

const Errors = {
    noAccessToken: "Security Error: No access token."
}

const Strings = {
    global: Global,
    branding: Branding,
    formats: Formats,
    errors: Errors
}

export default Strings;