/**
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

import { InteractionType, LogLevel } from "@azure/msal-browser";
import { policies } from ".";

export default {
    interactionType: InteractionType.Redirect,
    auth: {
        // App ID of your application. Can be found in your portal registration.
        clientId: 'cd34d2df-433e-4b1a-8ebf-7dff203b39d3',
        // URI of the tenant to authenticate and authorize with.
        authority: policies.b2c.authorities.signIn.authority,
        // An array of URIs that are known to be valid. Used in B2C scenarios.
        knownAuthorities: [policies.b2c.domain],
        // URI where the authorization code response is sent back to. 
        redirectUri: '/',
        // If true, will navigate back to the original request location before processing the authorization code response. 
        // If the redirectUri is the same as the original request location, this flag should be set to false.
        navigateToLoginRequestUrl: true,
        // URI that is redirected to after a logout() call is made.
        postLogoutRedirectUri: '/',
    },
    cache: {
        // Location of token cache in browser.
        cacheLocation: 'sessionStorage',
        // If true, stores cache items in cookies as well as browser cache. 
        // Should be set to true for use cases using IE.
        storeAuthStateInCookie: false,
        // If true and storeAuthStateInCookies is also enabled, 
        // MSAL adds the Secure flag to the browser cookie so it can only be sent over HTTPS.
        secureCookies: false,
    },
    system: {
        // Config object for logger.
        loggerOptions: {
            // Callback function which handles the logging of MSAL statements.
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
            // If true, personally identifiable information (PII) is included in logs.
            piiLoggingEnabled: false,
        },
        // Timeout in milliseconds to wait for popup operations to resolve.
        windowHashTimeout: 60000,
        // Timeout in milliseconds to wait for iframe operations to resolve.
        iframeHashTimeout: 6000,
        // Timeout in milliseconds to wait for iframe/popup operations resolve.
        //  If provided, will set default values for windowHashTimeout and iframeHashTimeout.
        //loadFrameTimeout: undefined,
        // Sets whether popups are opened asynchronously. 
        // When set to false, blank popups are opened before anything else happens. 
        // When set to true, popups are opened when making the network request. 
        // Can be set to true for scenarios where about:blank is not supported, 
        // e.g. desktop apps or progressive web apps
        //asyncPopups: false,
        // By default, MSAL will not allow redirect operations to be initiated when 
        // the application is inside an iframe. Set this flag to true to remove this check.
        //allowRedirectInIframe
    },
    telemetry: {
        // Telemetry options for applications using MSAL.js
        application: {
            // Unique string name of an application
            appName: "Florence",
            // Version of the application using MSAL
            appVersion: "1.0.0",
        }
    }
}