
// import { useMsal } from '@azure/msal-react'
import { faBars } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useState } from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'
import { DefaultLayout, SplitView } from '../../components/layouts'
// import AsideView from '../../components/layouts/AsideView'
import VerticalMenu from '../../components/ui/Menu/VerticalMenu'
import styles from './AnalyticsScreen.module.css'

type AnalyticsScreenProps = {

}

type ContextType = { expandedHandler: () => void }

const AnalyticsScreen = (props: AnalyticsScreenProps) => {

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const expandedHandler = useCallback(
        () => {
            setIsExpanded((prev) => !prev);
            if (isExpanded) {
                document.body.style.overflow = 'auto';
            } else {
                document.body.style.overflow = 'hidden';
            }
        },
        [isExpanded],
    )

    // const { instance, accounts } = useMsal();
    // const username = accounts && accounts[0] && accounts[0].username;
    // const name = accounts && accounts[0] && accounts[0].name;

    const expandedClass = isExpanded ? styles.expanded : '';
    return (
        <DefaultLayout>
            <SplitView
                leftWidth={isCollapsed ? 60 : 300}
                rightChildClass={[expandedClass, styles['right-pane']].join(' ')}
                leftChildClass={styles['left-pane']}
            >
                <>
                    <header className={["insights", `${styles.header} ${isCollapsed ? styles.collapsed : ''}`].join(" ")}>
                        <h3>
                            <button
                                className={styles.button} onClick={() => { setIsCollapsed(prev => !prev) }}
                                title="Toggle Side Menu">
                                <FontAwesomeIcon className={styles.icon} icon={faBars}></FontAwesomeIcon>
                            </button>
                            {!isCollapsed && 'Dashboards'} </h3>
                    </header>
                    <VerticalMenu isCollapsed={isCollapsed} />
                </>
                <Outlet context={{ expandedHandler }} />
            </SplitView>
            {/* <AsideView title="Dashboards II">
                <VerticalMenu />
                <Outlet context={{ expandedHandler }} />
            </AsideView> */}
        </DefaultLayout>
    )
}

export function useExpandedHandler() {
    return useOutletContext<ContextType>();
}

export default AnalyticsScreen