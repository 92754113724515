import { faPieChart } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import MenuItemModel from '../../../../models/MenuItemModel';
import { IMenuItem } from '../../../../types/interfaces';
import CardMenuItem from '../CardMenuItem';

import styles from './CardMenu.module.css';

type CardMenuProps = {
    menu?: MenuItemModel[] | undefined;
    loader?: React.ReactNode;
}

const paintMenuEntry = (menuItem: IMenuItem, index: number): React.ReactNode => {

    if (menuItem.feature) {
        switch (menuItem.feature.type) {
            case 4: // Dashboard
                return (
                    <CardMenuItem key={menuItem.id} menuItem={menuItem}>
                        {
                            menuItem.childMenuItems
                            && menuItem.childMenuItems.length > 0
                            && menuItem.childMenuItems.map(paintMenuEntry)
                        }
                    </CardMenuItem>
                );
            default:
                break;
        }
    } else {
        return (
            <>
                <h3 key={menuItem.id} className={styles['menu-category']}>

                    {menuItem.name}
                </h3>
                {/* <div className={styles['card-menu-groups']}> */}
                {
                    menuItem.childMenuItems
                    && menuItem.childMenuItems.length > 0
                    && menuItem.childMenuItems.map(paintMenuEntry)
                }
                {/* </div> */}

            </>
        )
    }
}

const CardMenu = ({ menu, loader }: CardMenuProps) => {
    return menu
        ? (<div className={styles['card-menu']}>
            {menu && menu.map(paintMenuEntry)}
        </div>)
        : <>{loader ? loader : <p className={styles.loading}>Loading</p>}</>
}

export default CardMenu