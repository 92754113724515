import { useMsal } from '@azure/msal-react';
import { LoaderFunctionArgs, useLoaderData, useParams } from 'react-router-dom';
import { protectedWebApi } from '../../../auth';
import UserForm from '../../../components/views/UserForm';
import UserFormWithRoles from '../../../components/views/UserFormWithRoles';
import UserModel from '../../../models/UserModel';
import UserService from '../../../services/UserService';
import styles from './UserEditScreen.module.scss';

// import { getAccessTokenForScopes } from '../../../index'
import { PublicClientApplication } from '@azure/msal-browser';
import getAccessTokenForScopes from '../../../functions/getAccessTokenForScopes';

interface UserEditScreenProps { }

const UserEditScreen = ({ ...props }: UserEditScreenProps) => {
    const { id } = useParams();
    const userData = useLoaderData() as UserModel;
    const { instance, accounts, inProgress } = useMsal();


    return (
        <>
            <header
                className={["screen-header", styles["screen-header"]].join(" ")}>
                <h3>Edit User</h3>
            </header>
            {/* <UserForm
                className={[
                    'screen-content',
                    styles['screen-content'],
                    styles['edit-user-form']
                ].join(' ')} /> */}

            <UserFormWithRoles user={userData} className={['screen-content', styles['screen-content'], styles['edit-user-form']].join(' ')} />
        </>
    )
}

export const loader = (msalInstance: PublicClientApplication) =>
    async ({ request, params }: LoaderFunctionArgs) => {
        console.log(request, params, msalInstance);
        const id = params.id;
        if (id) {
            try {

                const accessToken = await getAccessTokenForScopes(
                    msalInstance,
                    protectedWebApi.b2c.apiUser.incaUsers.scopes)
                if (accessToken) {
                    return await UserService.getUser(id, accessToken);
                }

            } catch (error) {
                console.error(error);
            }
        }
    }

export default UserEditScreen