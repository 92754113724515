
import { faPieChart } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Children } from 'react'
import { Link } from 'react-router-dom'
import { IMenuItem } from '../../../../types/interfaces'

import styles from './CardMenuItem.module.css'

type Props = {
    menuItem: IMenuItem,
    children: React.ReactNode,
}

const CardMenuItem = ({ menuItem, children }: Props) => {
    return (
        <div key={menuItem.id} className={["card-menu-card", styles.card].join(" ")} >
            <Link to={`/analytics/${menuItem.feature?.id}`} className={styles['card-link']}>
                <h3 title={menuItem.name}> <FontAwesomeIcon icon={faPieChart} /> {menuItem.name}</h3>
                {
                    menuItem.feature && (
                        <p className={styles['block-with-text']} title={menuItem.feature.description}>
                            {menuItem.feature.description}
                        </p>
                    )
                }
                {/* <p><strong>Menu Id: </strong>{menuItem.id}</p>
            <p><strong>Feature Id: </strong>{menuItem.feature && menuItem.feature.id}</p> */}


                {children}
            </Link>
        </div>
    )
}

export default CardMenuItem