import { Colors } from "../constants";
import { AuthZ } from "../library/security";
import protectedWebApi from '../auth/protected-web-api';
import { DashboardDTO } from "../types/dto";
import PBIReportModel from "../models/PBIReportModel";


const InsightsService = {
    getReports: async (accessToken?: string) => {
        if (!accessToken) {
            throw new Error("Access token is required");
        }
        try {
            const result = await AuthZ.fetch<DashboardDTO[]>(accessToken,
                protectedWebApi.b2c.apiInsights.apiReports.endpoint);
            if (result instanceof Response) {
                return result
            }
            return result.map((report: DashboardDTO) => new PBIReportModel(
                report.pbIreportId,
                report.featureId,
                report.name,
                report.isPBI,
                report.description
            ));
        } catch (error) {
            console.log(Colors.console.magenta, error);
            return Promise.reject(error);
        }
    },

    getEmbedConfig: async (id?: string, accessToken?: string,) => {
        if (!accessToken) {
            throw new Error("Access token is required");
        }
        if (!id) {
            throw new Error("Report id is required");
        }
        try {
            const result = await AuthZ.fetch<any>(accessToken,
                `${protectedWebApi.b2c.apiPowerBi.embedConfig.endpoint}/${id}`);
            return result;
        } catch (error) {
            console.log(Colors.console.magenta, error);
            return Promise.reject(error);
        }
    }

}

export default InsightsService;