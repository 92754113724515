import { User } from "../types/interfaces";

export default class UserModel implements User {

    private _active: boolean;
    private _avatar?: string | undefined;
    private _communicationUserId?: string | undefined;
    private _displayName: string;
    private _givenName: string;
    private _id: string | null;
    private _incaUserId?: string | undefined;
    private _mail: string;
    private _mobilePhone?: string | undefined;
    private _preferredLanguage?: string;
    private _surname: string;
    private _userPrincipalName?: string | undefined;
    private _roles?: string[] | undefined;
    public get roles(): string[] | undefined {
        return this._roles;
    }
    public set roles(value: string[] | undefined) {
        this._roles = value;
    }

    public get id(): string | null {
        return this._id;
    }
    public set id(value: string | null) {
        this._id = value;
    }
    public get givenName(): string {
        return this._givenName;
    }
    public set givenName(value: string) {
        this._givenName = value;
    }
    public get surname(): string {
        return this._surname;
    }
    public set surname(value: string) {
        this._surname = value;
    }
    public get displayName(): string {
        return this._displayName;
    }
    public set displayName(value: string) {
        this._displayName = value;
    }
    public get userPrincipalName(): string | undefined {
        return this._userPrincipalName;
    }
    public set userPrincipalName(value: string | undefined) {
        this._userPrincipalName = value;
    }
    public get mail(): string {
        return this._mail;
    }
    public set mail(value: string) {
        this._mail = value;
    }
    public get mobilePhone(): string | undefined {
        return this._mobilePhone;
    }
    public set mobilePhone(value: string | undefined) {
        this._mobilePhone = value;
    }

    public get incaUserId(): string | undefined {
        return this._incaUserId;
    }
    public set incaUserId(value: string | undefined) {
        this._incaUserId = value;
    }
    public get communicationUserId(): string | undefined {
        return this._communicationUserId;
    }
    public set communicationUserId(value: string | undefined) {
        this._communicationUserId = value;
    }
    public get preferredLanguage(): string | undefined {
        return this._preferredLanguage;
    }
    public set preferredLanguage(value: string | undefined) {
        this._preferredLanguage = value;
    }
    public get active(): boolean {
        return this._active;
    }
    public set active(value: boolean) {
        this._active = value;
    }

    public get avatar(): string | undefined {
        return this._avatar;
    }
    public set avatar(value: string | undefined) {
        this._avatar = value;
    }


    constructor(
        id: string | null,
        active: boolean,
        mail: string,
        givenName: string,
        surname: string,
        displayName: string,
        userPrincipalName?: string,
        mobilePhone?: string,
        preferredLanguage?: string,
        roles?: string[],
        incaUserId?: string,
        communicationUserId?: string,
        avatar?: string
    ) {
        this._id = id;
        this._givenName = givenName;
        this._surname = surname;
        this._displayName = displayName;
        this._userPrincipalName = userPrincipalName;
        this._mail = mail;
        this._mobilePhone = mobilePhone;
        this._incaUserId = incaUserId;
        this._communicationUserId = communicationUserId;
        this._preferredLanguage = preferredLanguage;
        this._active = active;
        this._avatar = avatar;
        this._roles = roles;
    }



}