import { ApplicationIdentity } from "../types/global";
import { IRole } from "../types/interfaces";

export default class RoleModel implements IRole {
    private _id: string;
    private _name: string;
    private _description: string;
    private _application: ApplicationIdentity;
    private _permissionIds: string[];

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }
    public get description(): string {
        return this._description;
    }
    public set description(value: string) {
        this._description = value;
    }
    public get application(): ApplicationIdentity {
        return this._application;
    }
    public set application(value: ApplicationIdentity) {
        this._application = value;
    }
    public get permissionIds(): string[] {
        return this._permissionIds;
    }
    public set permissionIds(value: string[]) {
        this._permissionIds = value;
    }

    /**
     *
     */
    constructor(
        id: string,
        name: string,
        description: string,
        application: ApplicationIdentity,
        permissionIds: string[]
    ) {
        this._id = id;
        this._name = name;
        this._description = description;
        this._application = application;
        this._permissionIds = permissionIds;
    }

}