import { IPBIReport } from "../types/interfaces";

export default class PBIReportModel implements IPBIReport {
    private _id: string;
    private _menuRef: string;
    private _name: string;
    private _description?: string | undefined;
    private _isPBI: boolean;

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get menuRef(): string {
        return this._menuRef;
    }
    public set menuRef(value: string) {
        this._menuRef = value;
    }
    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }
    public get description(): string | undefined {
        return this._description;
    }
    public set description(value: string | undefined) {
        this._description = value;
    }
    public get isPBI(): boolean {
        return this._isPBI;
    }
    public set isPBI(value: boolean) {
        this._isPBI = value;
    }

    constructor(id: string, menuRef: string, name: string, isPBI: boolean, description?: string) {
        this._id = id;
        this._menuRef = menuRef;
        this._name = name;
        this._description = description;
        this._isPBI = isPBI;
    }

}