
const AuthZ = {
    fetch: async<T>(accessToken: string, endpoint: string, options?: RequestInit): Promise<T | Response> => {
        // // const headers = new Headers();
        // // headers.append('Authorization', `Bearer ${accessToken}`);

        const headers = {
            'Authorization': `Bearer ${accessToken}`
        } as HeadersInit

        const init: RequestInit = {
            headers: { ...options?.headers, ...headers },
            method: options?.method || 'GET',
            body: options?.body,
            cache: options?.cache,
            credentials: options?.credentials,
            integrity: options?.integrity,
            keepalive: options?.keepalive,
            mode: options?.mode,
            redirect: options?.redirect,
            referrer: options?.referrer,
            referrerPolicy: options?.referrerPolicy,
            signal: options?.signal,
            window: options?.window
        };

        console.log(init)

        try {
            const response = await fetch(endpoint, init);
            if (response.status === 401) {
                console.log(response);
                throw new Error("Unauthorized");
            } else if (response.status === 403) {
                throw new Error("Forbidden");
            } else if (response.status === 404) {
                throw new Error("Not found");
            } else if (response.status === 500) {
                throw new Error("Internal server error");
            } else if (!response.ok) {
                // throw new Error(response.statusText);
                console.log(response, "aqui")
                return await response
            }
            return await response.json();
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }
}

export default AuthZ;