export const Palette = {
    jungleGreen: {
        value: '#1ab394',
        tint: {
            10: '#31bb9f',
            20: '#48c2a9',
            30: '#5fcab4',
            40: '#76d1bf',
            50: '#8dd9ca',
            60: '#a3e1d4',
            70: '#bae8df',
            80: '#d1f0ea',
            90: '#e8f7f4',
        },
        shade: {
            10: '#17a185',
            20: '#158f76',
            30: '#127d68',
            40: '#106b59',
            50: '#0d5a4a',
            60: '#0a483b',
            70: '#08362c',
            80: '#05241e',
            90: '#03120f',
        }
    },
    blueNCS: {
        value: '#1a85b3',
        tint: {
            10: '#3191bb',
            20: '#489dc2',
            30: '#5faaca',
            40: '#76b6d1',
            50: '#8dc2d9',
            60: '#a3cee1',
            70: '#badae8',
            80: '#d1e7f0',
            90: '#e8f3f7',
        },
        shade: {
            10: '#1778a1',
            20: '#156a8f',
            30: '#125d7d',
            40: '#10506b',
            50: '#0d435a',
            60: '#0a3548',
            70: '#082836',
            80: '#051b24',
            90: '#030d12',
        }
    },
    platinum: {
        value: '#e3e7e6',
        tint: {
            10: '#e6e9e9',
            20: '#e9eceb',
            30: '#ebeeee',
            40: '#eef1f0',
            50: '#f1f3f3',
            60: '#f4f5f5',
            70: '#f7f8f8',
            80: '#f9fafa',
            90: '#fcfdfd',
        },
        shade: {
            10: '#ccd0cf',
            20: '#b6b9b8',
            30: '#9fa2a1',
            40: '#888b8a',
            50: '#727473',
            60: '#5b5c5c',
            70: '#444545',
            80: '#2d2e2e',
            90: '#171717',
        }
    },
    appleGreen: {
        value: '#85b31a',
        tint: {
            10: '#91bb31',
            20: '#9dc248',
            30: '#aaca5f',
            40: '#b6d176',
            50: '#c2d98d',
            60: '#cee1a3',
            70: '#dae8ba',
            80: '#e7f0d1',
            90: '#f3f7e8',
        },
        shade: {
            10: '#78a117',
            20: '#6a8f15',
            30: '#5d7d12',
            40: '#506b10',
            50: '#435a0d',
            60: '#35480a',
            70: '#283608',
            80: '#1b2405',
            90: '#0d1203',
        }
    },
    rajah: {
        value: '#f8ac59',
        tint: {
            10: '#f9b46a',
            20: '#f9bd7a',
            30: '#fac58b',
            40: '#fbcd9b',
            50: '#fcd6ac',
            60: '#fcdebd',
            70: '#fde6cd',
            80: '#feeede',
            90: '#fef7ee',
        },
        shade: {
            10: '#df9b50',
            20: '#c68a47',
            30: '#ae783e',
            40: '#956735',
            50: '#7c562d',
            60: '#634524',
            70: '#4a341b',
            80: '#322212',
            90: '#191109',
        }
    },
    rust: {
        value: '#b3481a',
        tint: {
            10: '#bb5a31',
            20: '#c26d48',
            30: '#ca7f5f',
            40: '#d19176',
            50: '#d9a48d',
            60: '#e1b6a3',
            70: '#e8c8ba',
            80: '#f0dad1',
            90: '#f7ede8',
        },
        shade: {
            10: '#a14117',
            20: '#8f3a15',
            30: '#6b2b10',
            40: '#5a240d',
            50: '#481d0a',
            60: '#361608',
            70: '#240e05',
            80: '#120703',

        }
    },
    redNCS: {
        value: '#b31a39',
        tint: {
            10: '#bb314d',
            20: '#c24861',
            30: '#ca5f74',
            40: '#d17688',
            50: '#d98d9c',
            60: '#e1a3b0',
            70: '#e8bac4',
            80: '#f0d1d7',
            90: '#f7e8eb',
        },
        shade: {
            10: '#a11733',
            20: '#8f152e',
            30: '#7d1228',
            40: '#6b1022',
            50: '#5a0d1d',
            60: '#480a17',
            70: '#360811',
            80: '#24050b',
            90: '#120306',
        }
    },
    white: {
        value: '#ffffff',
    },
    black: {
        value: '#000000',
    },
};

//https://fluentsite.z22.web.core.windows.net/0.51.7/color-schemes
const Colors = {
    light: {
        primary: Palette.jungleGreen.value,
        text: Palette.platinum.shade[80],
    },
    dark: {
        primary: Palette.jungleGreen.value,
        text: Palette.platinum.tint[80],
    },
    console: {
        red: '\x1b[31m%s\x1b[0m',
        green: '\x1b[32m%s\x1b[0m',
        yellow: '\x1b[33m%s\x1b[0m',
        blue: '\x1b[34m%s\x1b[0m',
        magenta: '\x1b[35m%s\x1b[0m',
        cyan: '\x1b[36m%s\x1b[0m',
    }
}

export default Colors;