import protectedWebApi from "../auth/protected-web-api";
import { Colors } from "../constants";
import { AuthZ } from "../library/security";
import FeatureModel from "../models/FeatureModel";
import MenuItemModel from "../models/MenuItemModel";
import { MenuItemDTO } from "../types/dto";


const createMenuItem = (item: MenuItemDTO): MenuItemModel => {
    return new MenuItemModel(
        item.menuItemId,
        item.name,
        item.featureId ? new FeatureModel(
            item.featureId,
            item.featureName,
            item.featureDescription,
            item.featureType,
            item.featureTypeAsString
        ) : null,
        item.order,
        item.childMenuItems && item.childMenuItems.length > 0
            ? item.childMenuItems.map(createMenuItem)
            : []
    )
};

const MenuService = {
    getMenuItems: async (accessToken?: string) => {
        if (!accessToken) {
            throw new Error("Access token is required");
        }
        try {
            const result = await AuthZ.fetch<MenuItemDTO[]>(accessToken,
                protectedWebApi.b2c.apiMenu.menuItems.endpoint);
            if (result instanceof Response) {
                return result
            }
            return result.length > 0
                ? result.sort((menuItem1: MenuItemDTO, menuItem2: MenuItemDTO) => {
                    if (menuItem1.order < menuItem2.order) {
                        return -1;
                    }
                    if (menuItem1.order > menuItem2.order) {
                        return 1;
                    }
                    return 0;
                }).map(createMenuItem)
                : [];
        } catch (error) {
            console.log(Colors.console.magenta, error);
            return Promise.reject(error);
        }
    },
}

export default MenuService;