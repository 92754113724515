import { faHexagonExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import styles from './Page404.module.css'

type Page404Props = {}

const Page404Screen = ({ }: Page404Props) => {
    return (
        <div className={styles.page404}>
            < FontAwesomeIcon icon={faHexagonExclamation} />
            <div>

                <h1><FontAwesomeIcon icon={faHexagonExclamation} /> Error 404</h1>
                <p>
                    Maybe this screen was moved?<br />
                    Got deleted?<br />
                    Is hiding out in quarentine? <br />
                    Never existed in the first place?
                </p>
                <p>Let's go <Link to="/">home</Link> and try from there.</p>
            </div>
        </div>
    )
}

export default Page404Screen