//menuItemModel.ts

import { IFeature, IMenuItem } from "../types/interfaces";


export default class MenuItemModel implements IMenuItem {
    private _id: string;
    private _name: string;
    private _feature: IFeature | null;
    private _order: number;
    private _childMenuItems: IMenuItem[];

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }
    public get feature(): IFeature | null {
        return this._feature;
    }
    public set feature(value: IFeature | null) {
        this._feature = value;
    }
    public get order(): number {
        return this._order;
    }
    public set order(value: number) {
        this._order = value;
    }
    public get childMenuItems(): IMenuItem[] {
        return this._childMenuItems;
    }
    public set childMenuItems(value: IMenuItem[]) {
        this._childMenuItems = value;
    }

    constructor(id: string, name: string, feature: IFeature | null, order: number, childMenuItems: IMenuItem[]) {
        this._id = id;
        this._name = name;
        this._feature = feature;
        this._order = order;
        this._childMenuItems = childMenuItems;
    }

}